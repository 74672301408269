import React from "react"

export const LinearProgress: React.FC<{
  progress: number
  bgColor?: string
  color: string | ((progress: number) => string)
  withCircle?: boolean
}> = ({progress: progressUnprocessed, color, withCircle, bgColor = "var(--color-cr-grey-5)"}) => {
  const progress = Math.min(100, Math.max(0, progressUnprocessed))

  const colorToUse = React.useMemo(() => {
    return typeof color === "string" ? color : color(progress)
  }, [progress, color])

  return (
    <div className={"relative h-full w-full rounded-full"} style={{backgroundColor: bgColor}}>
      <div
        className={"absolute top-0 left-0 h-full rounded-full transition-all"}
        style={{width: progress + "%", backgroundColor: colorToUse}}
      >
        {withCircle && (
          <div
            className={
              "absolute right-0 aspect-square h-[300%] translate-x-1/2 -translate-y-1/3 rounded-full border-[3px]"
            }
            style={{borderColor: colorToUse, backgroundColor: bgColor}}
          />
        )}
      </div>
    </div>
  )
}
