import React from "react"
import {Trans, useTranslation} from "react-i18next"

import {ConfirmModal} from "../../../../components/Modal.tsx"
import {commonTransComponents} from "../../../../utils/i18n.tsx"
import {AdvancedLeadsContext, PFLContext} from "./context/context.ts"

export const AdvancedSearchResetPrompt: React.FC = () => {
  const {t} = useTranslation()

  const {prompt, setStage, setPrompt, allFilters} = AdvancedLeadsContext.useContext()
  const {setIsFiltersOpen} = PFLContext.useContext()

  const handleConfirm = React.useCallback(() => {
    setStage("filtering")
    allFilters.clear()
    setIsFiltersOpen(true)
  }, [allFilters, setIsFiltersOpen, setStage])

  return (
    <ConfirmModal
      onConfirm={handleConfirm}
      isOpen={prompt === "reset"}
      onClose={() => setPrompt(undefined)}
      variant={"warning"}
      title={t("Prospects_ImportModal_LeadsStep_Advanced_Prompt_Reset_Title")}
      confirmButtonText={t("Prospects_ImportModal_LeadsStep_Advanced_Prompt_Reset_Confirm")}
    >
      <Trans
        i18nKey={"Prospects_ImportModal_LeadsStep_Advanced_Prompt_Reset_Text"}
        components={commonTransComponents}
      />
    </ConfirmModal>
  )
}
