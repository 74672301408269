import React from "react"
import {useTranslation} from "react-i18next"

import {Accordion} from "../../../../components/Accordion.tsx"
import {AutocompleteFilterContent} from "../../../../components/AutocompleteFilter.tsx"
import {getLeadsAutocompleteQuery} from "../../../../queries/leads.ts"
import {ALeadAutocompleteFields} from "../../../../services/types.generated.ts"
import {LeadsFilteringContext} from "../../../Leads/context.ts"
import {AdvancedLeadsContext} from "../AdvancedSearch/context/context.ts"
import {FilterTitle} from "./utilityComponents/FilterTitle.tsx"
import {useIsFilterDisabled} from "./utilityComponents/useIsFilterDisabled.ts"

export const Position: React.FC = () => {
  const {t} = useTranslation()

  const basicFiltering = LeadsFilteringContext.useOptionalContext()
  const advancedFiltering = AdvancedLeadsContext.useOptionalContext()
  const positions = advancedFiltering?.positions ?? basicFiltering?.positions
  if (!positions) {
    throw new Error("No context found")
  }

  const isDisabled = useIsFilterDisabled()

  return (
    <Accordion
      title={
        <FilterTitle property={positions} disabled={isDisabled}>
          {t("Prospects_ImportModal_LeadsStep_Filter_Position")}
        </FilterTitle>
      }
      defaultOpen={false}
      listClassName={"flex flex-col gap-4"}
    >
      <AutocompleteFilterContent
        useAutocompleteQuery={getLeadsAutocompleteQuery(ALeadAutocompleteFields.Position)}
        onChange={positions.setValue}
        value={positions.value}
        valueOnTop
        disabled={isDisabled}
        allowSubmitOnEnter
      />
    </Accordion>
  )
}
