import React from "react"

import {TPFLContext} from "../types.ts"

export const usePFLContext = (onClose: () => void): TPFLContext => {
  const [isFiltersOpen, setIsFiltersOpen] = React.useState(false)
  const [isAdvancedFiltering, setIsAdvancedFiltering] = React.useState(false)

  return React.useMemo(
    () => ({
      isFiltersOpen,
      setIsFiltersOpen,
      isAdvancedFiltering,
      setIsAdvancedFiltering,
      onClose,
    }),
    [isAdvancedFiltering, isFiltersOpen, onClose]
  )
}
