import React from "react"
import {skipToken, useMutation, useQuery, useQueryClient} from "@tanstack/react-query"

import {EOrderDirection, TOrderBy} from "../components/Table/utils/shared.ts"
import {TAddingStageColumn, TSearchingStageColumn} from "../routes/Prospects/ProspectsFromLeads/AdvancedSearch/types.ts"
import api, {leadsAdvancedStepGeneralKey, prospectsGeneralKey, queryKey} from "../services"
import {
  AAdvancedSearchFilter,
  ADetailedDraftedProspectSortByValues,
  ADetailedDraftedProspectSortDirectionValues,
  ADraftedProspectSortByValues,
  ADraftedProspectSortDirectionValues,
  AUserTypes,
} from "../services/types.generated.ts"
import {useValueByUserType} from "../utils/userTypes.ts"

const draftedListColumnToApi = (
  column: TSearchingStageColumn | undefined
): ADraftedProspectSortByValues | undefined => {
  switch (column) {
    case "name":
      return ADraftedProspectSortByValues.OrganizationName
    default:
      return undefined
  }
}
function draftedListDirectionToApi(direction?: EOrderDirection): ADraftedProspectSortDirectionValues | undefined {
  switch (direction) {
    case EOrderDirection.ASC:
      return ADraftedProspectSortDirectionValues.Asc
    case EOrderDirection.DESC:
      return ADraftedProspectSortDirectionValues.Desc
    case undefined:
      return undefined
  }
}

export function useAdvancedSearchDraftedListQuery({
  sessionId,
  orderBy,
}: {
  sessionId: number
  orderBy: TOrderBy<TSearchingStageColumn>
}) {
  const [hasMore, setHasMore] = React.useState(false)

  const queryFn = useValueByUserType({
    [AUserTypes.SaasSalesPerson]: api.saasSalesPeople.leadsAdvancedSearchSessionsDraftedProspectsDetail,
    [AUserTypes.SalesPerson]: api.salesPeople.leadsAdvancedSearchSessionsDraftedProspectsDetail,
  })

  return useQuery({
    queryKey: queryKey.leadsAdvancedDraftedList(sessionId, orderBy),
    queryFn:
      sessionId == null
        ? skipToken
        : async () => {
            const result = await queryFn(sessionId, {
              page: 1,
              per_page: 100,
              sort_by: draftedListColumnToApi(orderBy?.column),
              sort_direction: draftedListDirectionToApi(orderBy?.direction),
            })

            setHasMore(!!result.data.meta?.ongoing_search)

            return result.data
          },
    refetchInterval: hasMore ? 2000 : undefined,
  })
}

const addedListColumnToApi = (
  column: TAddingStageColumn | undefined
): ADetailedDraftedProspectSortByValues | undefined => {
  switch (column) {
    case "person":
      return ADetailedDraftedProspectSortByValues.FullName
    case "company":
      return ADetailedDraftedProspectSortByValues.OrganizationName
    default:
      return undefined
  }
}
function addedListDirectionToApi(direction?: EOrderDirection): ADetailedDraftedProspectSortDirectionValues | undefined {
  switch (direction) {
    case EOrderDirection.ASC:
      return ADetailedDraftedProspectSortDirectionValues.Asc
    case EOrderDirection.DESC:
      return ADetailedDraftedProspectSortDirectionValues.Desc
    case undefined:
      return undefined
  }
}

export function useAdvancedSearchAddedListQuery({
  sessionId,
  orderBy,
}: {
  sessionId: number
  orderBy: TOrderBy<TAddingStageColumn>
}) {
  const [hasMore, setHasMore] = React.useState(false)

  const queryFn = useValueByUserType({
    [AUserTypes.SaasSalesPerson]: api.saasSalesPeople.leadsAdvancedSearchSessionsDraftedProspectsDetailedDetail,
    [AUserTypes.SalesPerson]: api.salesPeople.leadsAdvancedSearchSessionsDraftedProspectsDetailedDetail,
  })

  return useQuery({
    queryKey: queryKey.leadsAdvancedAddedList(sessionId, orderBy),
    queryFn:
      sessionId == null
        ? skipToken
        : async () => {
            const result = await queryFn(sessionId, {
              page: 1,
              per_page: 100,
              sort_by: addedListColumnToApi(orderBy?.column),
              sort_direction: addedListDirectionToApi(orderBy?.direction),
            })

            setHasMore(!!result.data.meta?.ongoing_search)

            return result.data
          },
    refetchInterval: hasMore ? 2000 : undefined,
  })
}

export function useAdvancedSearchSessionCreateMutation() {
  const mutationFn = useValueByUserType({
    [AUserTypes.SaasSalesPerson]: api.saasSalesPeople.leadsAdvancedSearchSessionsCreate,
    [AUserTypes.SalesPerson]: api.salesPeople.leadsAdvancedSearchSessionsCreate,
  })

  return useMutation({
    mutationFn: async ({assignmentId, filter}: {assignmentId: number; filter: AAdvancedSearchFilter}) => {
      return (
        await mutationFn({
          assignment_id: assignmentId,
          advanced_search_filter: filter,
        })
      ).data.advanced_search_session
    },
  })
}

export function useAdvancedSearchSessionCreateProspectsMutation() {
  const queryClient = useQueryClient()

  const mutationFn = useValueByUserType({
    [AUserTypes.SaasSalesPerson]: api.saasSalesPeople.leadsAdvancedSearchSessionsDraftedProspectsAddToProspectsCreate,
    [AUserTypes.SalesPerson]: api.salesPeople.leadsAdvancedSearchSessionsDraftedProspectsAddToProspectsCreate,
  })

  return useMutation({
    mutationFn: async ({sessionId, ids}: {sessionId: number; ids: number[]}) => {
      return (
        await mutationFn(sessionId, {
          "ids[]": ids,
        })
      ).data.advanced_search_session
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: prospectsGeneralKey})
    },
  })
}

export function useAdvancedSearchMarkWebsiteViewedMutation() {
  const queryClient = useQueryClient()

  const mutationFn = useValueByUserType({
    [AUserTypes.SaasSalesPerson]: api.saasSalesPeople.leadsAdvancedSearchSessionsDraftedProspectsViewedPartialUpdate,
    [AUserTypes.SalesPerson]: api.salesPeople.leadsAdvancedSearchSessionsDraftedProspectsViewedPartialUpdate,
  })

  return useMutation({
    mutationFn: ({sessionId, prospectId}: {sessionId: number; prospectId: number}) => mutationFn(sessionId, prospectId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({queryKey: leadsAdvancedStepGeneralKey(variables.sessionId)})
    },
  })
}

export function useAdvancedSearchSearchStartMutation() {
  const mutationFn = useValueByUserType({
    [AUserTypes.SaasSalesPerson]:
      api.saasSalesPeople.leadsAdvancedSearchSessionsDraftedProspectsSearchForDraftedProspectsCreate,
    [AUserTypes.SalesPerson]:
      api.salesPeople.leadsAdvancedSearchSessionsDraftedProspectsSearchForDraftedProspectsCreate,
  })

  return useMutation({
    mutationFn: (sessionId: number) => mutationFn(sessionId),
  })
}

export function useAdvancedSearchAddingStartMutation() {
  const mutationFn = useValueByUserType({
    [AUserTypes.SaasSalesPerson]:
      api.saasSalesPeople.leadsAdvancedSearchSessionsDraftedProspectsSearchForContactInformationCreate,
    [AUserTypes.SalesPerson]:
      api.salesPeople.leadsAdvancedSearchSessionsDraftedProspectsSearchForContactInformationCreate,
  })

  return useMutation({
    mutationFn: ({ids, sessionId}: {sessionId: number; ids: number[]}) =>
      mutationFn(sessionId, {
        "ids[]": ids,
      }),
  })
}
