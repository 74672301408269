import React from "react"
import {Trans, useTranslation} from "react-i18next"

import {Link} from "../../../../../components/Link.tsx"
import {TableNoRows} from "../../../../../components/Table/TableNoRows.tsx"
import {commonTransComponents} from "../../../../../utils/i18n.tsx"
import {AdvancedLeadsContext, PFLContext} from "../context/context.ts"

export const NoResults: React.FC = () => {
  const {t} = useTranslation()

  const {setIsFiltersOpen} = PFLContext.useContext()
  const {setStage} = AdvancedLeadsContext.useContext()

  const handleReset = React.useCallback(() => {
    setIsFiltersOpen(true)
    setStage("filtering")
  }, [setIsFiltersOpen, setStage])

  return (
    <TableNoRows>
      <div className={"flex flex-col items-center justify-center gap-10 p-10"}>
        <h2 className={"text-center text-2xl font-semibold"}>{t("NoResults")}</h2>
        <div className={"text-center"}>
          <Trans
            i18nKey={"Prospects_ImportModal_LeadsStep_Advanced_NoResults_Text"}
            components={{...commonTransComponents, resetLink: <Link onClick={handleReset} />}}
          />
        </div>
      </div>
    </TableNoRows>
  )
}
