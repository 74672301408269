import React from "react"

export function useAutoUpdateRef<T>(value: T): React.RefObject<T> {
  const ref = React.useRef<T>(value)

  React.useEffect(() => {
    ref.current = value
  }, [value])

  return ref
}

export function combineRef<T>(...refs: Array<React.ForwardedRef<T> | undefined>): (instance: T | null) => void {
  return value => {
    refs.forEach(ref => ref && assignRef(ref, value))
  }
}
function assignRef<T>(ref: React.ForwardedRef<T>, value: T | null) {
  if (ref === null) {
    return
  }

  if ("current" in ref) {
    ref.current = value
    return
  }

  ref(value)
}
