import React from "react"
import {useTranslation} from "react-i18next"
import {PlusIcon} from "@heroicons/react/20/solid"
import {InformationCircleIcon, TrashIcon} from "@heroicons/react/24/outline"

import {GenericErrorAlert} from "../../../../../components/Alert.tsx"
import {AnonymizedEmail, AnonymizedPhone} from "../../../../../components/Anonymized.tsx"
import Button from "../../../../../components/Button.tsx"
import {RadioBase} from "../../../../../components/fields/RadioButton.tsx"
import {Loading} from "../../../../../components/Loading.tsx"
import {Table} from "../../../../../components/Table/Table.tsx"
import {TableRow} from "../../../../../components/Table/TableRow.tsx"
import {TColumnsMeta, TOrderBy} from "../../../../../components/Table/utils/shared.ts"
import {TabItem, TabsContainer} from "../../../../../components/Tabs.tsx"
import {useAdvancedSearchAddedListQuery} from "../../../../../queries/advancedSearch.ts"
import {ADetailedAdvancedSearchDraftedProspect} from "../../../../../services/types.generated.ts"
import {EMPTY_ARRAY} from "../../../../../utils"
import {ProspectsFromLeadsFrame} from "../../ProspectsFromLeadsFrame.tsx"
import {ProspectsFromLeadsLayout} from "../../ProspectsFromLeadsLayout.tsx"
import {AddingStageContext, AdvancedLeadsContext} from "../context/context.ts"
import {useAddingStage} from "../context/useAddingStage.ts"
import {AddingStageButton} from "../NextAdvancedSearchPageButton.tsx"
import {tableLoadingIndicator} from "../Table/common.tsx"
import {useCompanySizeColumn} from "../Table/companySizeColumn.tsx"
import {NoResults} from "../Table/NoResults.tsx"
import {useWebsiteColumn} from "../Table/websiteColumn.tsx"
import {TAddingStageColumn, TAddingStageRow} from "../types.ts"
import {hideGroupedBorder} from "../utils.ts"

function isColumnLoading(
  column: keyof ADetailedAdvancedSearchDraftedProspect,
  loadingColumns: ADetailedAdvancedSearchDraftedProspect["loading_keys"]
) {
  return loadingColumns.includes(column)
}

export const AdvancedSearchAddingStage: React.FC = () => {
  const {t} = useTranslation()

  const {sessionId} = AdvancedLeadsContext.useContext()

  if (sessionId == null) {
    throw new Error("sessionId is not initialized")
  }

  const [orderBy, setOrderBy] = React.useState<TOrderBy<TAddingStageColumn>>(undefined)

  const {data, error, refetch, isLoading} = useAdvancedSearchAddedListQuery({
    sessionId,
    orderBy,
  })
  const isLoadingData = (isLoading || data?.meta?.ongoing_search) ?? false
  const prospects = data?.drafted_prospects ?? (EMPTY_ARRAY as unknown as ADetailedAdvancedSearchDraftedProspect[])

  const addingStageContextValue = AddingStageContext.useProviderValue(useAddingStage(prospects, orderBy, setOrderBy))
  const {
    rows,
    tab,
    mainContacts,
    removedSearchIds,
    onSelectMainContact,
    setTab,
    addRemovedSearchId,
    removeRemovedSearchId,
  } = addingStageContextValue

  const websiteColumn = useWebsiteColumn<TAddingStageColumn, TAddingStageRow>()
  const companySizeColumn = useCompanySizeColumn<TAddingStageColumn, TAddingStageRow>()

  const columnsMeta = React.useMemo<TColumnsMeta<TAddingStageColumn, TAddingStageRow>>(
    () => [
      {
        column: "main contact",
        size: "min-content",
        headerCellClassName: "!bg-cr-blue-super-light",
        cellClassName: hideGroupedBorder(rows),
        HeaderCellValue: () => t("Leads_Table_MainContact"),
        CellValue: ({row}) => {
          const groupId = row.group_id

          return groupId == null ? (
            <RadioBase onChange={() => undefined} checked={true} disabled={tab !== "added"} />
          ) : (
            <RadioBase
              onChange={() => onSelectMainContact(row)}
              checked={mainContacts[groupId] === row.id}
              disabled={tab !== "added"}
            />
          )
        },
      },
      {
        column: "person",
        sortFn: true,
        headerCellClassName: "!bg-cr-blue-super-light",
        cellClassName: hideGroupedBorder(rows),
        HeaderCellValue: () => t("Leads_Table_ContactPerson"),
        CellValue: ({row}) => {
          return (
            <div className={"flex flex-col gap-1"}>
              {row.full_name && <div>{row.full_name}</div>}
              {row.position && <div className={"text-cr-grey-50"}>{row.position}</div>}
            </div>
          )
        },
      },
      {
        column: "company",
        sortFn: true,
        headerCellClassName: "!bg-cr-blue-super-light",
        cellClassName: hideGroupedBorder(rows),
        HeaderCellValue: () => t("Leads_Table_Company"),
        CellValue: ({row}) => {
          return (
            <div className={"flex flex-col gap-1"}>
              {row.organization_name && <div>{row.organization_name}</div>}
              {row.segment && row.segment.length > 0 && (
                <div className={"text-cr-grey-50"}>{row.segment.join(", ")}</div>
              )}
            </div>
          )
        },
      },
      {
        ...websiteColumn,
        cellClassName: hideGroupedBorder(rows),
      },
      {
        ...companySizeColumn,
        cellClassName: hideGroupedBorder(rows),
      },
      {
        column: "phone",
        headerCellClassName: "!bg-cr-blue-super-light",
        cellClassName: hideGroupedBorder(rows),
        HeaderCellValue: () => t("Leads_Table_Phone"),
        CellValue: ({row}) => {
          return isColumnLoading("phone_number", row.loading_keys) ? (
            tableLoadingIndicator
          ) : (
            <AnonymizedPhone phone={row.phone_number} />
          )
        },
      },
      {
        column: "email",
        headerCellClassName: "!bg-cr-blue-super-light",
        cellClassName: hideGroupedBorder(rows),
        HeaderCellValue: () => t("Leads_Table_Email"),
        CellValue: ({row}) => {
          return isColumnLoading("email", row.loading_keys) ? (
            tableLoadingIndicator
          ) : (
            <AnonymizedEmail email={row.email} />
          )
        },
      },
    ],
    [rows, websiteColumn, companySizeColumn, t, tab, mainContacts, onSelectMainContact]
  )

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  const addedNum = prospects.length - removedSearchIds.length
  const removedNum = removedSearchIds.length

  return (
    <AddingStageContext value={addingStageContextValue}>
      <ProspectsFromLeadsLayout nextStepButton={<AddingStageButton />}>
        <ProspectsFromLeadsFrame className={"p-0"}>
          <div className={"flex h-full w-full flex-col"}>
            <div className={"flex justify-between gap-10 border-b border-cr-blue-light"}>
              <TabsContainer size={"sm"} className={"min-h-[36px] w-auto border-b-0 px-4"}>
                <TabItem isActive={tab === "added"} onClick={() => setTab("added")}>
                  <div className={"relative flex items-baseline gap-1 text-cr-black"}>
                    <span className={"text-base font-semibold"}>{t("Leads_Table_Total")} </span>
                    <span className={"text-sm"}>({addedNum})</span>
                    {isLoadingData && (
                      <Loading size={"xs"} fullSpace={false} containerClassName={"absolute top-1.5 -right-6"} />
                    )}
                  </div>
                </TabItem>
                <TabItem isActive={tab === "removed"} onClick={() => setTab("removed")}>
                  <div className={"flex items-baseline gap-1 text-cr-black"}>
                    <span className={"text-base font-semibold"}>{t("Leads_Table_Removed")} </span>
                    <span className={"text-sm"}>({removedNum})</span>
                  </div>
                </TabItem>
              </TabsContainer>
              <div className={"flex grow items-center justify-end gap-1 pr-4 text-sm font-semibold text-cr-yellow"}>
                <InformationCircleIcon className={"size-6 shrink-0"} />
                <span>{t("Prospects_ImportModal_LeadsStep_Advanced_Adding_Tip")}</span>
              </div>
            </div>
            <div className={"flex grow"}>
              <Table<TAddingStageColumn, TAddingStageRow>
                grow
                ghost
                data={rows}
                className={"absolute rounded-t-none"}
                columnsMeta={columnsMeta}
                orderBy={orderBy}
                onOrder={setOrderBy}
              >
                {({data: rowData, pinnedColumn}) =>
                  data?.meta?.ongoing_search === false && rowData.length === 0 ? (
                    <NoResults />
                  ) : (
                    rowData.map((row, index) => (
                      <div className={"group/row relative contents"} key={row.id}>
                        <TableRow
                          row={row}
                          rowIndex={index}
                          pinnedColumn={pinnedColumn}
                          onClick={row.group_id == null ? undefined : () => onSelectMainContact(row)}
                        />
                        <div
                          className={"col-span-full hidden items-center justify-end pr-6 group-hover/row:flex"}
                          style={{gridRow: index + 2}}
                        >
                          <div className={"z-30"}>
                            {tab === "added" ? (
                              <Button
                                variant={"outlined"}
                                shape={"round"}
                                color={"red"}
                                size={"xs"}
                                iconLeft={<TrashIcon className={"size-5"} />}
                                onClick={() => addRemovedSearchId(row.id)}
                              >
                                {t("Prospects_ImportModal_LeadsStep_Advanced_Adding_RemoveButton")}
                              </Button>
                            ) : (
                              <Button
                                variant={"outlined"}
                                shape={"round"}
                                color={"blue"}
                                size={"xs"}
                                iconLeft={<PlusIcon className={"size-5"} />}
                                onClick={() => removeRemovedSearchId(row.id)}
                              >
                                {t("Prospects_ImportModal_LeadsStep_Advanced_Adding_UnremoveButton")}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  )
                }
              </Table>
            </div>
          </div>
        </ProspectsFromLeadsFrame>
      </ProspectsFromLeadsLayout>
    </AddingStageContext>
  )
}
