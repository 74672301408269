import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import {Badge} from "../../../components/Badge.tsx"
import Button from "../../../components/Button.tsx"
import {Loading} from "../../../components/Loading.tsx"
import Pagination from "../../../components/Pagination.tsx"
import {RowCountPickerLS, usePageSize} from "../../../components/RowCountPicker.tsx"
import {useLeadsForAssignmentQuery} from "../../../queries/leads.ts"
import {CombineProviders} from "../../../utils/context.tsx"
import {commonTransComponents} from "../../../utils/i18n.tsx"
import {DeleteModal} from "../../Leads/components/DeleteModal.tsx"
import {DeletingContext, LeadsFilteringContext} from "../../Leads/context.ts"
import {useLeadsFiltering} from "../../Leads/useLeadsFiltering.ts"
import {ProspectsContext} from "../shared/context.ts"
import {DataTable} from "./DataTable.tsx"
import {ProspectsFromLeadsFrame} from "./ProspectsFromLeadsFrame.tsx"
import {ProspectsFromLeadsLayout} from "./ProspectsFromLeadsLayout.tsx"

const paginationKey = "pfl basic"

export const BasicSearch: React.FC = () => {
  const leadsFiltering = LeadsFilteringContext.useProviderValue(useLeadsFiltering())
  const {allFilters, clearRows} = leadsFiltering

  const {assignmentId} = ProspectsContext.useContext()

  if (assignmentId == null) {
    throw new Error("Error: assignmentId not set")
  }

  const {data, isFetching, error, refetch, pagination} = useLeadsForAssignmentQuery({
    assignmentId,
    pageSize: usePageSize(paginationKey, 25),
    ...leadsFiltering,
  })

  const deleteContextValue = DeletingContext.useProviderValue(null)

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }
  if (!data) {
    return <Loading size={"xl"} delayShow={false} />
  }

  return (
    <CombineProviders
      providers={[DeletingContext.combined(deleteContextValue), LeadsFilteringContext.combined(leadsFiltering)]}
    >
      <ProspectsFromLeadsLayout>
        {allFilters.isActive && data.data.leads.length > 0 && (
          <>
            <DataTable
              total={data.data.meta?.pagination?.total_count ?? 0}
              data={data.data.leads}
              isLoading={isFetching}
            />
            <RowCountPickerLS
              defaultValue={25}
              total={pagination.total}
              autoHide
              paginationKey={paginationKey}
              openUp
            />
            <Pagination {...pagination} autoHide />
          </>
        )}

        {allFilters.isActive &&
          data.data.leads.length === 0 &&
          (isFetching || !allFilters.isActiveDebounced ? <Loading size={"xl"} delayShow={false} /> : <NoFilterMatch />)}
        {!allFilters.isActive && <NoFilter />}

        <DeleteModal onDelete={clearRows} />
      </ProspectsFromLeadsLayout>
    </CombineProviders>
  )
}

export const NoFilterMatch: React.FC = () => {
  const {t} = useTranslation()

  const {allFilters} = LeadsFilteringContext.useContext()

  return (
    <ProspectsFromLeadsFrame>
      <div className={"flex animate-delay-show flex-col items-center gap-8"}>
        <div className={"relative"}>
          <MagnifyingGlassIcon className={"size-32 stroke-1 text-cr-blue-mid"} />
          <Badge color={"red"} className={"absolute top-1 right-7 px-2.5 py-0.5 text-lg"}>
            0
          </Badge>
        </div>
        <h2 className={"text-lg font-bold"}>{t("Prospects_ImportModal_LeadsStep_NoResults_Title")}</h2>
        <div>
          <Trans i18nKey={"Prospects_ImportModal_LeadsStep_NoResults_Text"} components={commonTransComponents} />
        </div>
        <div>
          <Button onClick={allFilters.clear}>{t("Prospects_ImportModal_LeadsStep_NoResults_ResetButton")}</Button>
        </div>
      </div>
    </ProspectsFromLeadsFrame>
  )
}

export const NoFilter: React.FC = () => {
  const {t} = useTranslation()

  return (
    <ProspectsFromLeadsFrame>
      <div className={"flex flex-col items-center gap-8"}>
        <MagnifyingGlassIcon className={"size-32 stroke-1 text-cr-blue-mid"} />
        <h2 className={"text-lg"}>{t("Prospects_ImportModal_LeadsStep_NoFilter")}</h2>
      </div>
    </ProspectsFromLeadsFrame>
  )
}
