export const hideGroupedBorder =
  (data: Array<{group_id?: number | undefined | null; id: number} | {id: "loading"}>) =>
  ({rowIndex}: {rowIndex: number}) => {
    const previousRow = data.at(rowIndex - 1)
    const thisRow = data.at(rowIndex)
    const nextRow = data.at(rowIndex + 1)

    const previousRowGroupId = previousRow?.id === "loading" ? undefined : previousRow?.group_id
    const thisRowGroupId = thisRow?.id === "loading" ? undefined : thisRow?.group_id
    const nextRowGroupId = nextRow?.id === "loading" ? undefined : nextRow?.group_id

    if (thisRowGroupId == null) {
      return undefined
    }

    return [
      previousRowGroupId === thisRowGroupId ? "!border-t-0" : undefined,
      nextRowGroupId === thisRowGroupId ? "!border-b-0" : undefined,
    ]
      .filter(x => !!x)
      .join(" ")
  }
