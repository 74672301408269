import React from "react"
import {useTranslation} from "react-i18next"

import {Accordion} from "../../../../components/Accordion.tsx"
import {AutocompleteFilterContent} from "../../../../components/AutocompleteFilter.tsx"
import {useLeadsCountryAutocompleteQuery} from "../../../../queries/leads.ts"
import {LeadsFilteringContext} from "../../../Leads/context.ts"
import {AdvancedLeadsContext} from "../AdvancedSearch/context/context.ts"
import {FilterTitle} from "./utilityComponents/FilterTitle.tsx"
import {useIsFilterDisabled} from "./utilityComponents/useIsFilterDisabled.ts"

export const Country: React.FC = () => {
  const {t} = useTranslation()

  const basicFiltering = LeadsFilteringContext.useOptionalContext()
  const advancedFiltering = AdvancedLeadsContext.useOptionalContext()
  const countries = advancedFiltering?.countries ?? basicFiltering?.countries
  if (!countries) {
    throw new Error("No context found")
  }

  const isDisabled = useIsFilterDisabled()

  return (
    <Accordion
      title={
        <FilterTitle property={countries} disabled={isDisabled}>
          {t("Prospects_ImportModal_LeadsStep_Filter_Country")}
        </FilterTitle>
      }
      defaultOpen={false}
      listClassName={"flex flex-col gap-4"}
    >
      <AutocompleteFilterContent
        useAutocompleteQuery={useLeadsCountryAutocompleteQuery}
        onChange={countries.setValue}
        value={countries.value}
        valueOnTop
        disabled={isDisabled}
      />
    </Accordion>
  )
}
