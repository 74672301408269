import React from "react"
import {Trans, useTranslation} from "react-i18next"

import {ConfirmModal} from "../../../../components/Modal.tsx"
import {commonTransComponents} from "../../../../utils/i18n.tsx"
import {AdvancedLeadsContext, PFLContext} from "./context/context.ts"

export const AdvancedSearchTurnOffPrompt: React.FC = () => {
  const {t} = useTranslation()

  const {prompt, setPrompt} = AdvancedLeadsContext.useContext()
  const {setIsFiltersOpen, setIsAdvancedFiltering} = PFLContext.useContext()

  return (
    <ConfirmModal
      onConfirm={() => {
        setIsAdvancedFiltering(false)
        setIsFiltersOpen(true)
      }}
      isOpen={prompt === "turn off"}
      onClose={() => {
        setPrompt(undefined)
      }}
      variant={"warning"}
      title={t("Prospects_ImportModal_LeadsStep_Advanced_Prompt_TurnOff_Title")}
      confirmButtonText={t("Prospects_ImportModal_LeadsStep_Advanced_Prompt_TurnOff_Confirm")}
    >
      <Trans
        i18nKey={"Prospects_ImportModal_LeadsStep_Advanced_Prompt_TurnOff_Text"}
        components={commonTransComponents}
      />
    </ConfirmModal>
  )
}
