import React from "react"
import {useTranslation} from "react-i18next"
import {Outlet, useMatch, useNavigate} from "react-router"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock.tsx"
import {Loading} from "../../../components/Loading.tsx"
import {SellingsBlockBadge} from "../../../components/SellingsBlock"
import {TabItem, TabsContainer} from "../../../components/Tabs.tsx"
import {useSalesCycleNewsDetailQuery} from "../../../queries/salesCycles.ts"
import {useUserSettingsOrLogout} from "../../../queries/user.ts"
import {useNumParam} from "../../../utils/hooks.tsx"
import {isSaaSSalespersonUser, isSalesPersonUser} from "../../../utils/types.ts"

export type TTab = "news" | "prospects" | "details"

export const SellingDetails: React.FC = () => {
  const {t} = useTranslation()
  const {user} = useUserSettingsOrLogout()

  const navigate = useNavigate()

  const salesCycleId = useNumParam("salesCycleId")
  const match = useMatch("sellings/:salesCycleId/:tab/*")
  const tabParam = match?.params.tab as TTab | undefined

  const handleSelectTab = (tab: TTab) => {
    navigate(`/sellings/${salesCycleId}/${tab}`)
  }

  React.useEffect(() => {
    if (tabParam) {
      return
    }

    navigate(`/sellings/${salesCycleId}/news`, {replace: true})
  }, [salesCycleId, navigate, tabParam])

  const {data, error, isLoading, refetch} = useSalesCycleNewsDetailQuery(salesCycleId)

  const isProspectsEnabled =
    data?.sales_talent_prospects_enabled &&
    (isSaaSSalespersonUser(user) || (isSalesPersonUser(user) && !data?.saas_selling))

  const isDetailsEnabled = isSalesPersonUser(user)

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  if (isLoading || !data) {
    return <Loading size={"xl"} delayShow fullSpace />
  }

  return (
    <div className={"mb-8 flex flex-col"}>
      <LayoutBlock outerClassName={"pt-16"} innerClassName={"flex flex-col gap-9"}>
        <div className={"flex items-center justify-between gap-8"}>
          <h1 className={"text-4xl font-semibold text-cr-blue"}>{data.name}</h1>
          <SellingsBlockBadge isActive={true} status={data.status} />
        </div>

        <TabsContainer>
          <TabItem isActive={tabParam === "news"} onClick={() => handleSelectTab("news")}>
            {t("SellingDetails_Tabs_News")}
          </TabItem>
          {isProspectsEnabled && (
            <TabItem isActive={tabParam === "prospects"} onClick={() => handleSelectTab("prospects")}>
              {t("SellingDetails_Tabs_Prospects")}
            </TabItem>
          )}
          {isDetailsEnabled && (
            <TabItem isActive={tabParam === "details"} onClick={() => handleSelectTab("details")}>
              {t("SellingDetails_Tabs_Details")}
            </TabItem>
          )}
        </TabsContainer>
      </LayoutBlock>

      <React.Suspense fallback={<Loading size={"xl"} />}>
        <Outlet />
      </React.Suspense>
    </div>
  )
}
