import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline"

import Sparkles from "../../../../../assets/sparkles.svg?react"
import Button from "../../../../../components/Button.tsx"
import {
  useAdvancedSearchSearchStartMutation,
  useAdvancedSearchSessionCreateMutation,
} from "../../../../../queries/advancedSearch.ts"
import {rollbar} from "../../../../../services"
import requestError from "../../../../../services/requestError.tsx"
import {AAdvancedSearchFilter} from "../../../../../services/types.generated.ts"
import {EGTMEvent, emitGTMEvent} from "../../../../../utils/gtm.tsx"
import {commonTransComponents} from "../../../../../utils/i18n.tsx"
import {useAutoUpdateRef} from "../../../../../utils/ref.ts"
import {ProspectsContext} from "../../../shared/context.ts"
import {ProspectsFromLeadsFrame} from "../../ProspectsFromLeadsFrame.tsx"
import {ProspectsFromLeadsLayout} from "../../ProspectsFromLeadsLayout.tsx"
import {AdvancedLeadsContext, PFLContext} from "../context/context.ts"
import {TAdvancedLeadsContext} from "../types.ts"

function filteringContextToAdvancedSearchFilter(filteringContext: TAdvancedLeadsContext): AAdvancedSearchFilter {
  return {
    cities: filteringContext.cities.value.map(city => city.value),
    country_ids: filteringContext.countries.value.map(country => country.value),
    positions: filteringContext.positions.value.map(position => position.value),
    segments: filteringContext.segments.value.map(segment => segment.value),
  }
}

export const AdvancedSearchFilteringStage: React.FC = () => {
  const {t} = useTranslation()

  const advancedLeadsContext = AdvancedLeadsContext.useContext()
  const advancedLeadsContextRef = useAutoUpdateRef(advancedLeadsContext)
  const {assignmentId} = ProspectsContext.useContext()
  const {setIsFiltersOpen} = PFLContext.useContext()

  const createAdvancedSearchSession = useAdvancedSearchSessionCreateMutation()
  const startAdvancedSearchSearch = useAdvancedSearchSearchStartMutation()

  const handleStartSearch = React.useCallback(async () => {
    if (assignmentId == null) {
      return
    }

    try {
      const session = await createAdvancedSearchSession.mutateAsync.call(null, {
        assignmentId,
        filter: filteringContextToAdvancedSearchFilter(advancedLeadsContextRef.current),
      })
      if (!session) {
        rollbar.error("Failed to create advanced search session")
        toast.error(t("Prospects_ImportModal_LeadsStep_Advanced_SessionFailed"))
        return
      }

      await startAdvancedSearchSearch.mutateAsync.call(null, session.id)

      emitGTMEvent({event: EGTMEvent.LEADS_ADVANCED_SEARCH_SEARCHING})

      advancedLeadsContextRef.current.setSessionId(session.id)
      advancedLeadsContextRef.current.setStage("searching")
      setIsFiltersOpen(false)
    } catch (e) {
      requestError(e)
    }
  }, [
    advancedLeadsContextRef,
    assignmentId,
    createAdvancedSearchSession.mutateAsync,
    setIsFiltersOpen,
    startAdvancedSearchSearch.mutateAsync,
    t,
  ])

  return (
    <ProspectsFromLeadsLayout>
      <ProspectsFromLeadsFrame>
        <div className={"flex flex-col items-center gap-10"}>
          <div className={"flex flex-col items-center gap-4"}>
            <div className={"flex items-center gap-4"}>
              <Sparkles className={"size-12"} />
              <h2 className={"text-4xl font-semibold"}>
                {t("Prospects_ImportModal_LeadsStep_Advanced_Filtering_Title")}
              </h2>
            </div>
            <div className={"text-center text-cr-grey-50"}>
              <Trans
                i18nKey={"Prospects_ImportModal_LeadsStep_Advanced_Filtering_Text"}
                components={commonTransComponents}
              />
            </div>
          </div>
          <Button
            iconLeft={<MagnifyingGlassIcon className={"size-5"} />}
            onClick={handleStartSearch}
            disabled={!advancedLeadsContext.allFilters.isActive}
          >
            {t("Prospects_ImportModal_LeadsStep_Advanced_Filtering_Button")}
          </Button>
        </div>
      </ProspectsFromLeadsFrame>
    </ProspectsFromLeadsLayout>
  )
}
