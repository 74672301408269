import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {UserGroupIcon} from "@heroicons/react/24/outline"

import Button, {ButtonLoading} from "../../../../components/Button.tsx"
import {VariantModal} from "../../../../components/Modal.tsx"
import {
  useAdvancedSearchAddedListQuery,
  useAdvancedSearchAddingStartMutation,
  useAdvancedSearchDraftedListQuery,
  useAdvancedSearchSessionCreateProspectsMutation,
} from "../../../../queries/advancedSearch.ts"
import requestError from "../../../../services/requestError.tsx"
import {EMPTY_ARRAY} from "../../../../utils"
import {EGTMEvent, emitGTMEvent} from "../../../../utils/gtm.tsx"
import {commonTransComponents} from "../../../../utils/i18n.tsx"
import {AddingStageContext, AdvancedLeadsContext, PFLContext, SearchingStageContext} from "./context/context.ts"

export const SearchingStageButton: React.FC = () => {
  const {t} = useTranslation()

  const {setStage, sessionId} = AdvancedLeadsContext.useContext()
  const {removedSearchIds, orderBy} = SearchingStageContext.useContext()

  if (sessionId == null) {
    throw new Error("sessionId is not initialized")
  }

  const {data} = useAdvancedSearchDraftedListQuery({sessionId, orderBy})

  const startAddingMutation = useAdvancedSearchAddingStartMutation()

  const total = data?.meta?.pagination.total_count ?? 0
  const removed = removedSearchIds.length
  const added = total - removed

  const handleClick = async () => {
    const ids =
      data?.drafted_prospects
        ?.filter(prospect => !removedSearchIds.includes(prospect.id))
        .map(prospect => prospect.id) ?? []

    if (ids.length === 0 || sessionId == null) {
      return
    }

    try {
      await startAddingMutation.mutateAsync({
        sessionId,
        ids,
      })

      emitGTMEvent({event: EGTMEvent.LEADS_ADVANCED_SEARCH_ADDING, added, total, removed})
      setStage("adding")
    } catch (e) {
      requestError(e)
    }
  }

  return (
    <ButtonLoading
      onClick={handleClick}
      disabled={!data || data.meta?.ongoing_search || added === 0}
      isLoading={data?.meta?.ongoing_search}
    >
      {t("Prospects_ImportModal_LeadsStep_Advanced_Searching_NextStepButton")}
    </ButtonLoading>
  )
}

export const AddingStageButton: React.FC = () => {
  const {t} = useTranslation()

  const {onClose, setIsFiltersOpen} = PFLContext.useContext()
  const {mainContacts, removedSearchIds, orderBy} = AddingStageContext.useContext()
  const {setStage, sessionId} = AdvancedLeadsContext.useContext()

  if (sessionId == null) {
    throw new Error("sessionId is not initialized")
  }

  const {data} = useAdvancedSearchAddedListQuery({sessionId, orderBy})

  const createProspectsMutation = useAdvancedSearchSessionCreateProspectsMutation()

  const [isConfirming, setIsConfirming] = React.useState(false)

  const total = data?.meta?.pagination.total_count ?? 0
  const removed = removedSearchIds.length
  const added = total - removed

  const ids =
    data?.drafted_prospects
      ?.filter(
        prospect =>
          (prospect.group_id == null || Object.values(mainContacts).includes(prospect.id)) &&
          !removedSearchIds.includes(prospect.id)
      )
      .map(prospect => prospect.id) ?? (EMPTY_ARRAY as unknown as number[])

  const addProspects = React.useCallback(async () => {
    if (ids.length === 0 || sessionId == null) {
      return
    }

    try {
      await createProspectsMutation.mutateAsync({
        sessionId,
        ids,
      })

      toast.success(t("Prospects_ImportModal_LeadsStep_Advanced_AddedProspectsToast", {count: ids.length}))

      emitGTMEvent({
        event: EGTMEvent.LEADS_ADVANCED_SEARCH_COMPLETED,
        addedLeads: added,
        addedProspects: ids.length,
        total,
        removed,
      })
    } catch (e) {
      requestError(e)
      return false
    }
  }, [added, createProspectsMutation, ids, removed, sessionId, t, total])

  const handleAdd = React.useCallback(async () => {
    const result = await addProspects()
    if (result === false) {
      return
    }

    setIsConfirming(false)
    setStage("filtering")
    setIsFiltersOpen(true)
  }, [addProspects, setIsFiltersOpen, setStage])
  const handleAddAndClose = React.useCallback(async () => {
    const result = await addProspects()
    if (result === false) {
      return
    }

    setIsConfirming(false)
    onClose()
  }, [addProspects, onClose])

  return (
    <>
      <Button
        onClick={() => setIsConfirming(true)}
        disabled={!data || data.meta?.ongoing_search || added === 0}
        isLoading={data?.meta?.ongoing_search}
      >
        {t("Prospects_ImportModal_LeadsStep_Advanced_Adding_NextStepButton")}
      </Button>

      <VariantModal
        title={t("Prospects_ImportModal_LeadsStep_Advanced_Adding_AddModal_Title")}
        isOpen={isConfirming}
        onClose={() => setIsConfirming(false)}
        variant={"info"}
        Icon={UserGroupIcon}
        buttons={
          <>
            <ButtonLoading variant={"outlined"} color={"gray"} onClick={handleAdd}>
              {t("Prospects_ImportModal_LeadsStep_Advanced_Adding_AddModal_AddButton")}
            </ButtonLoading>
            <ButtonLoading color={"blue"} onClick={handleAddAndClose}>
              {t("Prospects_ImportModal_LeadsStep_Advanced_Adding_AddModal_AddAndCloseButton")}
            </ButtonLoading>
          </>
        }
      >
        <Trans
          i18nKey={"Prospects_ImportModal_LeadsStep_Advanced_Adding_AddModal_Text"}
          values={{count: ids.length}}
          components={{...commonTransComponents}}
        />
      </VariantModal>
    </>
  )
}
