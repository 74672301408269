import React from "react"
import {useTranslation} from "react-i18next"

import {Accordion} from "../../../../components/Accordion.tsx"
import {InputFilter} from "../../../../components/filters/InputFilter.tsx"
import {LeadsFilteringContext} from "../../../Leads/context.ts"
import {FilterTitle} from "./utilityComponents/FilterTitle.tsx"

export const CompanyName: React.FC = () => {
  const {t} = useTranslation()

  const {companyName} = LeadsFilteringContext.useContext()

  return (
    <Accordion
      title={
        <FilterTitle property={companyName}>{t("Prospects_ImportModal_LeadsStep_Filter_CompanyName")}</FilterTitle>
      }
      defaultOpen={false}
    >
      <InputFilter property={companyName} />
    </Accordion>
  )
}
