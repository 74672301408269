import React from "react"
import {useTranslation} from "react-i18next"

import {Accordion} from "../../../../components/Accordion.tsx"
import {AutocompleteFilterContent} from "../../../../components/AutocompleteFilter.tsx"
import {getLeadsAutocompleteQuery} from "../../../../queries/leads.ts"
import {ALeadAutocompleteFields} from "../../../../services/types.generated.ts"
import {LeadsFilteringContext} from "../../../Leads/context.ts"
import {AdvancedLeadsContext} from "../AdvancedSearch/context/context.ts"
import {FilterTitle} from "./utilityComponents/FilterTitle.tsx"
import {useIsFilterDisabled} from "./utilityComponents/useIsFilterDisabled.ts"

export const City: React.FC = () => {
  const {t} = useTranslation()

  const basicFiltering = LeadsFilteringContext.useOptionalContext()
  const advancedFiltering = AdvancedLeadsContext.useOptionalContext()
  const cities = advancedFiltering?.cities ?? basicFiltering?.cities
  if (!cities) {
    throw new Error("No context found")
  }

  const isDisabled = useIsFilterDisabled()

  return (
    <Accordion
      title={
        <FilterTitle property={cities} disabled={isDisabled}>
          {t("Prospects_ImportModal_LeadsStep_Filter_City")}
        </FilterTitle>
      }
      defaultOpen={false}
      listClassName={"flex flex-col gap-4"}
    >
      <AutocompleteFilterContent
        useAutocompleteQuery={getLeadsAutocompleteQuery(ALeadAutocompleteFields.City)}
        onChange={cities.setValue}
        value={cities.value}
        valueOnTop
        disabled={isDisabled}
      />
    </Accordion>
  )
}
