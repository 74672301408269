import React from "react"

import {createSimpleContext, TSimpleContext} from "../../../utils/context.tsx"
import {TCellProps, TCellValueProps} from "../Cell.tsx"
import {TFilterContentProps} from "../FilterButton.tsx"
import {THeaderCellProps, THeaderCellValueProps} from "../HeaderCell.tsx"

export enum EOrderDirection {
  DESC = -1,
  ASC = 1,
}

export type TOrderBy<TCol extends TColumns> = {column: TCol; direction: EOrderDirection} | undefined
export type TOrderByControls<TCol extends TColumns> = {
  orderBy: TOrderBy<TCol>
  setOrderBy: React.Dispatch<React.SetStateAction<TOrderBy<TCol>>>
}

export type TColumns = string

type TAlign = "left" | "right" | "center"
type TVerticalAlign = "top" | "bottom" | "center"

export type TSortFn<TRowData extends Record<string, any>> = (
  direction: EOrderDirection
) => (A: TRowData, B: TRowData) => number

export type TColumnMeta<TCol extends TColumns, TRowData extends Record<string, any>> = {
  column: TCol
  size?: string
  align?:
    | TAlign
    | ((ctx: {columnsMeta: TColumnsMeta<TCol, TRowData>; columnMeta: TColumnMeta<TCol, TRowData>}) => TAlign)
  verticalAlign?: TVerticalAlign
  sortFn?: TSortFn<TRowData> | true
  HeaderCell?: React.ComponentType<THeaderCellProps<TCol, TRowData>>
  headerCellClassName?: string
  cellClassName?: string | ((ctx: {columnMeta: TColumnMeta<TCol, TRowData>; rowIndex: number}) => string | undefined)
  Cell?: React.ComponentType<TCellProps<TCol, TRowData>>
  CellValue?: React.ComponentType<TCellValueProps<TCol, TRowData>>
  HeaderCellValue?: React.ComponentType<THeaderCellValueProps<TCol, TRowData>>
  FilterContent?: React.ComponentType<TFilterContentProps<TCol, TRowData>>
  isFiltered?: (columnMeta: TColumnMeta<TCol, TRowData>) => boolean
  clearFilter?: (columnMeta: TColumnMeta<TCol, TRowData>) => void
}
export type TColumnMetaWithEmpty<TCol extends TColumns, TRowData extends Record<string, any>> =
  | TColumnMeta<TCol, TRowData>
  | undefined
  | null
  | false

export type TColumnsMeta<TCol extends TColumns, TRowData extends Record<string, any>> = Array<
  TColumnMeta<TCol, TRowData>
>
export type TColumnsMetaWithEmpty<TCol extends TColumns, TRowData extends Record<string, any>> = Array<
  TColumnMetaWithEmpty<TCol, TRowData>
>

export type TTableContext<
  TCol extends TColumns,
  TRowData extends Record<string, any>,
  TMetadata extends Record<string, any> = Record<string, never>,
> = {
  columnsMeta: TColumnsMeta<TCol, TRowData>
  metadata: TMetadata
}

export const TableContext = createSimpleContext<TTableContext<never, never, never>>("table")
export function TypedTableContext<
  TCol extends TColumns,
  TRowData extends Record<string, any>,
  TMetadata extends Record<string, any> = Record<string, never>,
>() {
  return TableContext as unknown as TSimpleContext<TTableContext<TCol, TRowData, TMetadata>>
}

export type TRowId = string | number
