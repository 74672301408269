import React from "react"
import {useTranslation} from "react-i18next"
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import {Badge} from "../../../../../components/Badge.tsx"
import Button from "../../../../../components/Button.tsx"
import {Link, UnstyledLink} from "../../../../../components/Link.tsx"
import {LeadsFilteringContext} from "../../../../Leads/context.ts"
import {AdvancedLeadsContext, PFLContext} from "../../AdvancedSearch/context/context.ts"
import {City} from "../City.tsx"
import {CompanyName} from "../CompanyName.tsx"
import {CompanySize} from "../CompanySize.tsx"
import {ContactedByCompany} from "../ContactedByCompany.tsx"
import {Country} from "../Country.tsx"
import {Email} from "../Email.tsx"
import {Name} from "../Name.tsx"
import {Phone} from "../Phone.tsx"
import {Position} from "../Position.tsx"
import {Segment} from "../Segment.tsx"
import {FilterTitle} from "./FilterTitle.tsx"

export const FilterSidebar: React.FC = () => {
  const {isFiltersOpen, isAdvancedFiltering} = PFLContext.useContext()

  const basicFiltering = LeadsFilteringContext.useOptionalContext()
  const advancedFiltering = AdvancedLeadsContext.useOptionalContext()

  const activeNum = advancedFiltering?.allFilters.activeNum ?? basicFiltering?.allFilters.activeNum ?? 0
  const isDisabled =
    advancedFiltering?.stage === "searching" ||
    advancedFiltering?.stage === "adding" ||
    !basicFiltering?.allFilters.isActive

  const handleClear = React.useCallback(() => {
    isAdvancedFiltering ? advancedFiltering?.setPrompt.call(null, "reset") : basicFiltering?.allFilters.clear.call(null)
  }, [advancedFiltering?.setPrompt, basicFiltering?.allFilters.clear, isAdvancedFiltering])

  return (
    <div className={"relative isolate flex h-full"}>
      <div
        className={twMerge(
          "rounded-lg border border-cr-blue-light bg-cr-white",
          "relative z-10 grid h-full overflow-hidden transition-all",
          isFiltersOpen ? "w-0 grid-rows-[0fr] border-transparent opacity-0" : "w-10 grid-rows-[1fr] opacity-100"
        )}
      >
        <Closed activeNum={activeNum} disabled={isDisabled} onClear={handleClear} />
      </div>
      <div
        className={twMerge(
          "rounded-lg border border-cr-blue-light bg-cr-white",
          "relative z-10 grid h-full grid-rows-[1fr] overflow-hidden transition-all",
          isFiltersOpen ? "w-96 opacity-100" : "w-0 border-transparent opacity-0"
        )}
      >
        <Open />
      </div>
    </div>
  )
}

export const Closed: React.FC<{activeNum: number; onClear: () => void; disabled?: boolean}> = ({
  activeNum,
  onClear,
  disabled,
}) => {
  const {t} = useTranslation()

  const {isFiltersOpen, setIsFiltersOpen} = PFLContext.useContext()

  return (
    <div className={"flex flex-col items-stretch divide-y divide-cr-blue-light"} aria-hidden={isFiltersOpen}>
      <div className={"flex w-10 justify-center py-2"}>
        <Button
          variant={"outlined"}
          size={"xs"}
          color={"gray"}
          shape={"circular"}
          className={"size-6!"}
          onClick={() => setIsFiltersOpen(true)}
        >
          <ChevronRightIcon className={"size-4"} />
        </Button>
      </div>
      <div className={"flex w-10"}>
        <Link onClick={onClear} disabled={disabled} noUnderline variant={activeNum > 0 ? "primary" : "neutral"}>
          <span className={"sideways-text px-1.5 py-2"}>
            {t("Prospects_ImportModal_LeadsStep_Filter_Sidebar_Closed_Clear")}
          </span>
        </Link>
      </div>
      <div className={"flex w-10 grow"}>
        <UnstyledLink
          onClick={() => setIsFiltersOpen(true)}
          className={"flex w-10 cursor-pointer items-start justify-center py-1.5"}
        >
          <span className={"flex sideways-text items-center gap-2 py-2"}>
            {t("Prospects_ImportModal_LeadsStep_Filter_Sidebar_Header")}
            <Badge color={"blue"} className={"px-3 py-0.5 text-xs"}>
              {activeNum}
            </Badge>
          </span>
        </UnstyledLink>
      </div>
    </div>
  )
}

export const Open: React.FC = () => {
  const {t} = useTranslation()

  const {isFiltersOpen, setIsFiltersOpen, isAdvancedFiltering} = PFLContext.useContext()
  const basicSearch = LeadsFilteringContext.useOptionalContext()
  const advancedSearch = AdvancedLeadsContext.useOptionalContext()

  const allFilters = advancedSearch?.allFilters ?? basicSearch?.allFilters
  if (!allFilters) {
    throw new Error("No context found")
  }

  const isDisabled = advancedSearch?.stage === "searching" || advancedSearch?.stage === "adding"

  return (
    <div className={"flex min-h-0 w-96 flex-col"} aria-hidden={!isFiltersOpen}>
      <UnstyledLink
        className={twMerge(
          "flex items-center justify-between gap-4 px-4 py-2",
          "cursor-pointer whitespace-nowrap transition-none select-none",
          "border-b border-b-cr-blue-light"
        )}
        onClick={() => setIsFiltersOpen(false)}
      >
        <FilterTitle property={allFilters} disabled={isDisabled}>
          {t("Prospects_ImportModal_LeadsStep_Filter_Sidebar_Header")}
        </FilterTitle>
        <Button variant={"outlined"} size={"xs"} color={"gray"} shape={"circular"} className={"size-6!"}>
          <ChevronLeftIcon className={"absolute size-4"} />
        </Button>
      </UnstyledLink>
      <div className={"flex scrollbar-slim flex-col gap-4 overflow-auto scroll-shadow-y p-4"}>
        <div className={"flex flex-col gap-2"}>
          <h2 className={"font-bold"}>{t("Prospects_ImportModal_LeadsStep_Filter_Sidebar_Client")}</h2>

          {!isAdvancedFiltering && <Name />}
          {!isAdvancedFiltering && <Email />}
          {!isAdvancedFiltering && <Phone />}
          <Position />
        </div>
        <div className={"flex flex-col gap-2"}>
          <h2 className={"font-bold"}>{t("Prospects_ImportModal_LeadsStep_Filter_Sidebar_Company")}</h2>

          {!isAdvancedFiltering && <CompanyName />}
          <Segment />
          {!isAdvancedFiltering && <CompanySize />}
          {!isAdvancedFiltering && <ContactedByCompany />}
        </div>
        <div className={"flex flex-col gap-2"}>
          <h2 className={"font-bold"}>{t("Prospects_ImportModal_LeadsStep_Filter_Sidebar_Geo")}</h2>
          <Country />
          <City />
        </div>
      </div>
    </div>
  )
}
