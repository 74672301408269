import React from "react"
import {useTranslation} from "react-i18next"

import {Accordion} from "../../../../components/Accordion.tsx"
import {AutocompleteFilterContent} from "../../../../components/AutocompleteFilter.tsx"
import {useLeadsSegmentAutocompleteQuery} from "../../../../queries/leads.ts"
import {LeadsFilteringContext} from "../../../Leads/context.ts"
import {AdvancedLeadsContext} from "../AdvancedSearch/context/context.ts"
import {FilterTitle} from "./utilityComponents/FilterTitle.tsx"
import {useIsFilterDisabled} from "./utilityComponents/useIsFilterDisabled.ts"

export const Segment: React.FC = () => {
  const {t} = useTranslation()

  const basicFiltering = LeadsFilteringContext.useOptionalContext()
  const advancedFiltering = AdvancedLeadsContext.useOptionalContext()
  const segments = advancedFiltering?.segments ?? basicFiltering?.segments
  if (!segments) {
    throw new Error("No context found")
  }

  const isDisabled = useIsFilterDisabled()

  return (
    <Accordion
      title={
        <FilterTitle property={segments} disabled={isDisabled}>
          {t("Prospects_ImportModal_LeadsStep_Filter_Segment")}
        </FilterTitle>
      }
      defaultOpen={false}
      listClassName={"flex flex-col gap-4"}
    >
      <AutocompleteFilterContent
        useAutocompleteQuery={useLeadsSegmentAutocompleteQuery}
        onChange={segments.setValue}
        value={segments.value}
        valueOnTop
        disabled={isDisabled}
        allowSubmitOnEnter
      />
    </Accordion>
  )
}
