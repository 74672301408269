import React, {useCallback, useMemo} from "react"
import isEqual from "fast-deep-equal"

import {useDebouncedValue} from "./hooks.tsx"

export type TFilterProperty<T> = {
  value: T
  setValue: React.Dispatch<React.SetStateAction<T>>
  valueDebounced: T
  activeNum: number
  activeNumDebounced: number
  isActive: boolean
  isActiveDebounced: boolean
  isHiddenFromTotal: boolean
  clear: () => void
}
export type TFilterProperties<T extends {[K: string]: TFilterProperty<any>}> = T & {
  allFilters: Pick<
    TFilterProperty<any>,
    "activeNum" | "isActive" | "activeNumDebounced" | "isActiveDebounced" | "clear"
  >
}

export function useFilterProperty<T>(
  defaultValue: T,
  countActive: (value: T) => number,
  isHiddenFromTotal: boolean = false
): TFilterProperty<T> {
  // Memoize default value, so that we don't run into infinite loops
  const [defaultValueMemoized, setDefaultValueMemoized] = React.useState(defaultValue)
  React.useEffect(() => {
    if (isEqual(defaultValue, defaultValueMemoized)) {
      return
    }

    setDefaultValueMemoized(defaultValue)
  }, [defaultValue, defaultValueMemoized])

  const [value, setValue] = React.useState<T>(defaultValueMemoized)
  const valueDebounced = useDebouncedValue(value)
  const activeNum = useMemo(() => {
    return countActive(value)
  }, [countActive, value])
  const activeNumDebounced = useDebouncedValue(activeNum)
  const isActive = activeNum > 0
  const isActiveDebounced = useDebouncedValue(isActive)
  const clear = useCallback(() => setValue(defaultValueMemoized), [defaultValueMemoized])

  return React.useMemo(
    () => ({
      value,
      setValue,
      valueDebounced,
      activeNum,
      activeNumDebounced,
      isActive,
      isActiveDebounced,
      isHiddenFromTotal,
      clear,
    }),
    [activeNum, activeNumDebounced, clear, isActive, isActiveDebounced, isHiddenFromTotal, value, valueDebounced]
  )
}

export function useStringFilterProperty(isHiddenFromTotal = false): TFilterProperty<string> {
  return useFilterProperty<string>("", v => (v.trim().length ? 1 : 0), isHiddenFromTotal)
}

export function useMaybeFilterProperty<T>(isHiddenFromTotal = false): TFilterProperty<T | undefined> {
  return useFilterProperty<T | undefined>(undefined, v => (v === undefined ? 0 : 1), isHiddenFromTotal)
}

export function useArrayFilterProperty<T extends any[]>(isHiddenFromTotal = false): TFilterProperty<T> {
  return useFilterProperty<T>([] as unknown as T, v => v.length, isHiddenFromTotal)
}

export function useBooleanFilterProperty(defaultValue = false, isHiddenFromTotal = false): TFilterProperty<boolean> {
  return useFilterProperty<boolean>(defaultValue, v => (v ? 1 : 0), isHiddenFromTotal)
}

export function useFilterProperties<T extends {[K: string]: TFilterProperty<any>}>(
  properties: T
): TFilterProperties<T> {
  // Memoize properties, so that we don't run into infinite loops
  const [propertiesMemoized, setPropertiesMemoized] = React.useState(properties)
  React.useEffect(() => {
    if (isEqual(properties, propertiesMemoized)) {
      return
    }

    setPropertiesMemoized(properties)
  }, [propertiesMemoized, properties])

  const activeNum = Object.values(propertiesMemoized).reduce(
    (total, property) => (property.isHiddenFromTotal ? total : total + property.activeNum),
    0
  )
  const isActive = activeNum > 0

  const activeNumDebounced = useDebouncedValue(activeNum)
  const isActiveDebounced = useDebouncedValue(isActive)

  const clear = React.useCallback(() => {
    Object.values(propertiesMemoized).forEach(property => property.clear())
  }, [propertiesMemoized])

  return React.useMemo(() => {
    return {...propertiesMemoized, allFilters: {activeNum, isActive, activeNumDebounced, isActiveDebounced, clear}}
  }, [activeNum, activeNumDebounced, clear, isActive, isActiveDebounced, propertiesMemoized])
}
