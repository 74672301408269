import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {BuildingOffice2Icon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {EmailCell, PhoneNumberCell} from "../../../components/Anonymized.tsx"
import {ButtonLoading} from "../../../components/Button.tsx"
import {CheckboxBase} from "../../../components/fields/Checkbox.tsx"
import {DropdownBase} from "../../../components/formElements/Dropdown/Dropdown.tsx"
import {TOption} from "../../../components/formElements/Dropdown/types.ts"
import {Link} from "../../../components/Link.tsx"
import {Table} from "../../../components/Table/Table.tsx"
import {getColumnsFromMeta} from "../../../components/Table/utils/columns.ts"
import {rowCheckColumn, RowCheckContext} from "../../../components/Table/utils/rowChecking.tsx"
import {TColumnsMetaWithEmpty} from "../../../components/Table/utils/shared.ts"
import {useLSTableColumnsState} from "../../../components/Table/utils/useLSTableColumnsState.tsx"
import {TabItem, TabsContainer} from "../../../components/Tabs.tsx"
import {
  useAddProspectFromLeadMutation,
  useBulkAddProspectFromLeadMutation,
  useBulkRemoveProspectFromLeadMutation,
  useRemoveProspectFromLeadMutation,
} from "../../../queries/leads.ts"
import requestError from "../../../services/requestError.tsx"
import {ALead, ALeadWithAssignmentProspect} from "../../../services/types.generated.ts"
import {addHttpToURL, getFullName} from "../../../utils"
import {commonTransComponents, enumTranslKey} from "../../../utils/i18n.tsx"
import {DeletingContext, LeadsFilteringContext} from "../../Leads/context.ts"
import {ProspectsContext} from "../shared/context.ts"

export type TLeadsTableColumn =
  | "actions"
  | "in use"
  | "company"
  | "website"
  | "company size"
  | "segment"
  | "country"
  | "contact person"
  | "position"
  | "phone"
  | "email"

enum EBulkAction {
  UNSELECT,
  DELETE,
  ADD,
  REMOVE,
}

const tableId = "prospects/from-leads"

export const DataTable: React.FC<{
  data: Array<ALead | ALeadWithAssignmentProspect>
  total: number
  isLoading: boolean
}> = ({data, total, isLoading}) => {
  const {t} = useTranslation()

  const {checkedRows, orderBy, setOrderBy, checkRow} = LeadsFilteringContext.useContext()

  const bulkAddProspectFromLeadMutation = useBulkAddProspectFromLeadMutation()
  const bulkRemoveProspectFromLeadMutation = useBulkRemoveProspectFromLeadMutation()

  const bulkActionOptions = React.useMemo<Array<TOption<EBulkAction>>>(
    () => [
      {title: t("Leads_BulkAction_Unselect"), value: EBulkAction.UNSELECT},
      {title: t("Leads_BulkAction_Add"), value: EBulkAction.ADD},
      {title: t("Leads_BulkAction_Remove"), value: EBulkAction.REMOVE},
    ],
    [t]
  )

  const handleBulkAction = useOnBulkAction(bulkAddProspectFromLeadMutation, bulkRemoveProspectFromLeadMutation)

  const columnsMeta = React.useMemo<TColumnsMetaWithEmpty<TLeadsTableColumn, (typeof data)[number]>>(
    () => [
      {
        ...rowCheckColumn,
        headerCellClassName: "pr-0! bg-cr-blue-super-light!",
        HeaderCellValue: () => {
          const {onCheckAll, areAllChecked, checked} = RowCheckContext.useContext()

          return (
            <div className={"flex items-center justify-center"}>
              <div className={"shrink"}>
                <CheckboxBase name={"bulkCheck"} onChange={onCheckAll} checked={areAllChecked} />
              </div>
              <div className={twMerge("grow transition-all", checked.length === 0 && "pointer-events-none opacity-0")}>
                <DropdownBase
                  disabled={checked.length === 0}
                  options={bulkActionOptions}
                  value={null}
                  multiple={false}
                  onChange={handleBulkAction}
                  listWidth={"list-auto"}
                  placeholder={""}
                  mainButtonClassName={"p-0 input-border-ghost"}
                  caretClassName={"mr-0"}
                  placement={"bottom"}
                />
              </div>
            </div>
          )
        },
      },
      {
        column: "actions",
        headerCellClassName: "bg-cr-blue-super-light!",
        size: "min-content",
        HeaderCellValue: () => t("Leads_Table_Actions"),
        CellValue: Actions,
      },
      data.some(row => row.in_use) && {
        column: "in use",
        headerCellClassName: "bg-cr-blue-super-light!",
        size: "min-content",
        HeaderCellValue: () => t("Leads_Table_InUse"),
        CellValue: ({row}) => row.in_use && <BuildingOffice2Icon className={"size-4"} />,
      },
      {
        column: "company",
        headerCellClassName: "bg-cr-blue-super-light!",
        sortFn: true,
        HeaderCellValue: () => t("Leads_Table_Company"),
        CellValue: ({row}) => row.organization_name,
      },
      {
        column: "website",
        headerCellClassName: "bg-cr-blue-super-light!",
        HeaderCellValue: () => t("Leads_Table_Website"),
        CellValue: ({row}) =>
          row.website ? (
            <Link to={addHttpToURL(row.website)} target={"_blank"} flipUnderline>
              {row.website}
            </Link>
          ) : (
            "-"
          ),
      },
      {
        column: "company size",
        headerCellClassName: "bg-cr-blue-super-light!",
        HeaderCellValue: () => t("Leads_Table_CompanySize_Text"),
        CellValue: ({row}) => t(enumTranslKey("CompanySize", row.company_size)),
      },
      {
        column: "segment",
        headerCellClassName: "bg-cr-blue-super-light!",
        size: "max-content",
        HeaderCellValue: () => t("Leads_Table_Segment"),
        CellValue: ({row}) => (
          <span>{row.segment?.map(segment => t(enumTranslKey("Segment", segment), segment)).join(", ") ?? "-"}</span>
        ),
      },
      {
        column: "country",
        headerCellClassName: "bg-cr-blue-super-light!",
        size: "minmax(max-content, 400px)",
        sortFn: true,
        HeaderCellValue: () => t("Leads_Table_Country"),
        CellValue: ({row}) => <span>{[row.city, row.country?.name].filter(Boolean).join(", ") || "-"}</span>,
      },
      {
        column: "contact person",
        headerCellClassName: "bg-cr-blue-super-light!",
        size: "max-content",
        HeaderCellValue: () => t("Leads_Table_ContactPerson"),
        CellValue: ({row}) => (
          <span>
            {getFullName({first_name: row.contact_person_first_name, last_name: row.contact_person_last_name}) || "-"}
          </span>
        ),
      },
      {
        column: "position",
        headerCellClassName: "bg-cr-blue-super-light!",
        size: "minmax(150px, auto)",
        sortFn: true,
        HeaderCellValue: () => t("Leads_Table_Position"),
      },
      {
        column: "phone",
        headerCellClassName: "bg-cr-blue-super-light!",
        size: "max-content",
        HeaderCellValue: () => t("Leads_Table_Phone"),
        CellValue: PhoneNumberCell,
      },
      {
        column: "email",
        headerCellClassName: "bg-cr-blue-super-light!",
        sortFn: true,
        HeaderCellValue: () => t("Leads_Table_Email"),
        CellValue: EmailCell,
      },
    ],
    [bulkActionOptions, data, handleBulkAction, t]
  )

  return (
    <div className={"flex grow flex-col rounded-lg border border-cr-blue-light"}>
      <TabsContainer size={"sm"} className={"min-h-[36px] border-cr-blue-light px-4"}>
        <TabItem isActive>
          <div className={"flex items-baseline gap-1 text-cr-black"}>
            <span className={"text-base font-semibold"}>{t("Leads_Table_Total")} </span>
            <span className={"text-sm"}>({total})</span>
          </div>
        </TabItem>
      </TabsContainer>
      <Table<TLeadsTableColumn, (typeof data)[number]>
        {...useLSTableColumnsState(tableId, {columnsOrder: getColumnsFromMeta(columnsMeta), pinnedColumn: "company"})}
        grow
        ghost
        data={data}
        loading={isLoading || bulkAddProspectFromLeadMutation.isPending || bulkRemoveProspectFromLeadMutation.isPending}
        orderBy={orderBy}
        onOrder={setOrderBy}
        checkedRows={checkedRows}
        onCheckRow={checkRow}
        className={"absolute rounded-t-none"}
        columnsMeta={columnsMeta}
      />
    </div>
  )
}

function useOnBulkAction(
  addMutation: ReturnType<typeof useBulkAddProspectFromLeadMutation>,
  removeMutation: ReturnType<typeof useBulkRemoveProspectFromLeadMutation>
) {
  const {t} = useTranslation()

  const {clearRows, checkedRows} = LeadsFilteringContext.useContext()
  const deleteContext = DeletingContext.useContext()
  const assignmentId = ProspectsContext.useOptionalContext()?.assignmentId

  return React.useCallback(
    async (action: EBulkAction | undefined) => {
      switch (action) {
        case EBulkAction.UNSELECT:
          clearRows()
          break
        case EBulkAction.DELETE:
          deleteContext.setValue(checkedRows as number[])
          break
        case EBulkAction.ADD:
          if (assignmentId == null) {
            return
          }

          try {
            const response = await addMutation.mutateAsync({
              assignmentId,
              ids: checkedRows as number[],
            })

            const processed = response.data.processed_count
            const skipped = response.data.skipped_count

            if (processed > 0 && skipped === 0) {
              // all processed
              toast.success(t("Prospects_ImportModal_LeadsStep_Add_Bulk_AllProcessed", {count: processed}))
            }
            if (processed > 0 && skipped > 0) {
              // some processed, some skipped
              toast.success(
                <Trans
                  i18nKey={"Prospects_ImportModal_LeadsStep_Add_Bulk_SomeProcessed"}
                  components={commonTransComponents}
                  values={{
                    processed,
                    skipped,
                  }}
                />
              )
            }
            if (processed === 0) {
              // all skipped
              toast.warning(t("Prospects_ImportModal_LeadsStep_Add_Bulk_AllSkipped"))
            }
          } catch (e) {
            requestError(e)
          }
          break
        case EBulkAction.REMOVE:
          if (assignmentId == null) {
            return
          }

          try {
            const response = await removeMutation.mutateAsync({
              assignmentId,
              ids: checkedRows as number[],
            })
            const processed = response.data.processed_count
            const skipped = response.data.skipped_count

            if (processed > 0 && skipped === 0) {
              // all processed
              toast.success(t("Prospects_ImportModal_LeadsStep_Remove_Bulk_AllProcessed", {count: processed}))
            }
            if (processed > 0 && skipped > 0) {
              // some processed, some skipped
              toast.success(
                <Trans
                  i18nKey={"Prospects_ImportModal_LeadsStep_Remove_Bulk_SomeProcessed"}
                  components={commonTransComponents}
                  values={{
                    processed,
                    skipped,
                  }}
                />
              )
            }
            if (processed === 0) {
              // all skipped
              toast.warning(t("Prospects_ImportModal_LeadsStep_Remove_Bulk_AllSkipped"))
            }
          } catch (e) {
            requestError(e)
          }
          break
      }
    },
    [clearRows, deleteContext, checkedRows, assignmentId, addMutation, t, removeMutation]
  )
}

export const Actions: React.FC<{row: ALeadWithAssignmentProspect}> = ({row}) => {
  const {t} = useTranslation()

  const {assignmentId} = ProspectsContext.useContext()

  const addProspectFromLeadMutation = useAddProspectFromLeadMutation()
  const removeProspectFromLeadMutation = useRemoveProspectFromLeadMutation()

  const handleAdd = React.useCallback(async () => {
    if (assignmentId == null) {
      return
    }

    try {
      await addProspectFromLeadMutation.mutateAsync({
        assignmentId,
        leadId: row.id,
      })
    } catch (e) {
      requestError(e)
    }
  }, [addProspectFromLeadMutation, assignmentId, row.id])

  const handleRemove = React.useCallback(async () => {
    if (row.assignment_prospect?.id == null) {
      return
    }

    try {
      await removeProspectFromLeadMutation.mutateAsync(row.assignment_prospect?.id)
    } catch (e) {
      requestError(e)
    }
  }, [removeProspectFromLeadMutation, row.assignment_prospect?.id])

  return row.assignment_prospect ? (
    <ButtonLoading size={"xs"} fullWidth onClick={handleRemove}>
      {t("Prospects_ImportModal_LeadsStep_Remove_Button")}
    </ButtonLoading>
  ) : (
    <ButtonLoading variant={"outlined"} color={"gray"} size={"xs"} fullWidth onClick={handleAdd}>
      {t("Prospects_ImportModal_LeadsStep_Add_Button")}
    </ButtonLoading>
  )
}
