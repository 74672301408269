import React from "react"
import isEqual from "fast-deep-equal"

import {useAutoUpdateRef} from "./ref.ts"

export type TStateContext<T> = {
  value: T
  setValue: (setterFn: ((currentValue: T) => T) | T) => void
}
export type TStateContextFilled<T> = TStateContext<T> & {value: NonNullable<T>}

export function createStateContext<T>(name: string, setValueWrapper?: (desiredValue: T, currentValue: T) => T) {
  const context = React.createContext<TStateContext<T> | undefined>(undefined)

  return Object.assign(context, {
    useOptionalContext: (): TStateContext<T> | undefined => {
      return React.use(context)
    },
    useContext: (): TStateContext<T> => {
      const ctx = React.use(context)

      if (ctx === undefined) {
        throw new Error(`Context '${name}' not found`)
      }

      return ctx
    },
    useContextOrDie: (): TStateContextFilled<T> => {
      const ctx = React.use(context)

      if (ctx === undefined) {
        throw new Error(`Context '${name}' not found`)
      }
      if (ctx.value == null) {
        throw new Error(`Context '${name}' not filled`)
      }

      return ctx as TStateContextFilled<T>
    },
    useProviderValue: (initialValue: T): TStateContext<T> => {
      const [value, setValue] = React.useState<T>(initialValue)
      const valueRef = useAutoUpdateRef(value)

      const setValueReturn = React.useMemo<TStateContext<T>["setValue"]>(() => {
        return setValueWrapper
          ? fn => {
              setValue(
                setValueWrapper(
                  typeof fn === "function" ? (fn as (currentValue: T) => T)(valueRef.current) : fn,
                  valueRef.current
                )
              )
            }
          : setValue
      }, [valueRef])

      return React.useMemo(() => {
        return {value, setValue: setValueReturn}
      }, [setValueReturn, value])
    },
    combined: (value: TStateContext<T>): TCombinedProvider<TStateContext<T>> => {
      return [context, value]
    },
  })
}

export type TSimpleContext<T> = ReturnType<typeof createSimpleContext<T>>

export function createSimpleContext<T = never>(name: string) {
  const context = React.createContext<T | undefined>(undefined)

  return Object.assign(context, {
    useOptionalContext: (): T | undefined => {
      return React.use(context)
    },
    useContext: (): T => {
      const ctx = React.use(context)

      if (ctx === undefined) {
        throw new Error(`Context '${name}' not found`)
      }

      return ctx
    },
    useProviderValue: (value: T): T => {
      const [cachedValue, setCachedValue] = React.useState<T>(value)

      React.useEffect(() => {
        if (isEqual(cachedValue, value)) {
          return
        }

        setCachedValue(value)
      }, [value, cachedValue])

      return cachedValue
    },
    combined: (value: T): TCombinedProvider<T> => {
      return [context, value]
    },
  })
}

type TCombinedProvider<T> = [React.Context<T | undefined>, T]

export const CombineProviders: React.FC<{providers: Array<TCombinedProvider<any>>; children: React.ReactNode}> = ({
  providers,
  children,
}) => {
  return providers.reduceRight((acc, [Provider, value]) => <Provider value={value}>{acc}</Provider>, children)
}
