import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {BriefcaseIcon, BuildingOfficeIcon} from "@heroicons/react/24/outline"

import {HeroLayout} from "../../components/HeroLayout.tsx"
import {LayoutCard} from "../../components/LayoutCard.tsx"
import {Link} from "../../components/Link"
import {EGTMEvent, emitGTMEvent} from "../../utils/gtm.tsx"
import {useDocumentTitle} from "../../utils/hooks"
import {commonTransComponents} from "../../utils/i18n.tsx"

export const SignUpPick = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("Signup_DocumentTitle"))

  return (
    <HeroLayout>
      <LayoutCard title={t("Signup_Pick_Title")}>
        <div className={"text-cr-grey-50"}>{t("Signup_Pick_Subtitle")}</div>
        <div className={"grid grid-cols-1 gap-8 text-xl font-medium sm:grid-cols-2"}>
          <Link
            to={"/sign-up/company"}
            onClick={() => emitGTMEvent({event: EGTMEvent.COMPANY_SIGNUP_START})}
            className={"btn-radio-block px-8 py-6 text-center text-cr-grey-90"}
            noUnderline
          >
            <BuildingOfficeIcon className={"mx-auto mb-4 h-12 w-12"} />
            <Trans i18nKey={"Signup_Pick_Company"} components={commonTransComponents} />
          </Link>

          <Link
            to={"/sign-up/sales"}
            onClick={() => emitGTMEvent({event: EGTMEvent.SALES_SIGNUP_START})}
            className={"btn-radio-block px-8 py-6 text-center text-cr-grey-90"}
            noUnderline
          >
            <BriefcaseIcon className={"mx-auto mb-4 h-12 w-12"} />
            <Trans i18nKey={"Signup_Pick_Sales"} components={commonTransComponents} />
          </Link>
        </div>
      </LayoutCard>
    </HeroLayout>
  )
}
