import React from "react"
import {useTranslation} from "react-i18next"

import {Accordion} from "../../../../components/Accordion.tsx"
import {RadioBase} from "../../../../components/fields/RadioButton.tsx"
import {LeadsFilteringContext} from "../../../Leads/context.ts"
import {FilterTitle} from "./utilityComponents/FilterTitle.tsx"

export const Email: React.FC = () => {
  const {t} = useTranslation()

  const {email} = LeadsFilteringContext.useContext()

  return (
    <Accordion
      title={<FilterTitle property={email}>{t("Prospects_ImportModal_LeadsStep_Filter_Email_Title")}</FilterTitle>}
      defaultOpen={false}
      listClassName={"flex gap-4"}
    >
      <RadioBase onChange={() => email.setValue(true)} checked={email.value}>
        {t("Prospects_ImportModal_LeadsStep_Filter_Email_Yes")}
      </RadioBase>
      <RadioBase onChange={() => email.setValue(false)} checked={!email.value}>
        {t("Prospects_ImportModal_LeadsStep_Filter_Email_No")}
      </RadioBase>
    </Accordion>
  )
}
