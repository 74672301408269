import React from "react"
import {useTranslation} from "react-i18next"
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import Button from "../../../components/Button.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock.tsx"
import {TabsContainer} from "../../../components/Tabs.tsx"
import {getCurrentLanguage} from "../../../i18n.ts"
import {
  TAdminsProspectsAssignment,
  TAdminsProspectsIteration,
  useDraftTableQuery,
  useFinishedTableQuery,
  useWaitingForApprovalTableQuery,
} from "../../../queries/prospects.ts"
import {AAdminsProspectsSalesCycle} from "../../../services/types.generated.ts"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {useHorizontalSnapScroll} from "../../../utils/useHorizontalSnapScroll.tsx"
import {ProspectsContext, WarningsOnlyContext} from "../shared/context.ts"
import {NoProspects} from "../shared/NoProspects.tsx"
import {AssignmentPackage} from "./components/AssignmentPackage.tsx"
import {Section} from "./Section.tsx"

export const ProspectsIteration: React.FC<{
  salesCycle: AAdminsProspectsSalesCycle
}> = ({salesCycle}) => {
  const {t} = useTranslation()

  const prospectsContext = ProspectsContext.useContext()

  const activeAssignment = prospectsContext.assignment as TAdminsProspectsAssignment
  const activeIteration = prospectsContext.iteration as TAdminsProspectsIteration

  const {onScroll, containerClassName, currentItemRef, containerRef, leftButton, rightButton} =
    useHorizontalSnapScroll()

  const isWarningsOnly = WarningsOnlyContext.useOptionalContext()?.value

  if (!activeAssignment || !activeIteration) {
    return null
  }

  const hasNoProspects =
    activeAssignment.prospects_draft_count === 0 &&
    activeAssignment.prospects_ready_to_approve_count === 0 &&
    activeAssignment.prospects_finished_count === 0

  const canSendDraft =
    activeAssignment.prospects_draft_count +
      activeAssignment.prospects_ready_to_approve_count +
      activeAssignment.prospects_finished_count >=
    activeAssignment.prospects_mandatory_count

  return (
    <LayoutBlock innerClassName={"flex flex-col gap-9"}>
      <TabsContainer className={"min-h-20"}>
        <Button
          disabled={leftButton.disabled}
          onClick={leftButton.onClick}
          shape={"circular"}
          wrapperClassName={"self-center"}
        >
          <ArrowLeftIcon className={"w-6"} />
        </Button>

        <div
          className={twMerge(["no-scrollbar flex grow scroll-mt-20 gap-10", containerClassName])}
          ref={containerRef}
          onScroll={onScroll}
        >
          {activeIteration?.assignments.map(assignmentPackage => (
            <AssignmentPackage
              key={assignmentPackage.id}
              ref={assignmentPackage.id === activeAssignment.id ? currentItemRef : undefined}
              onClick={() => prospectsContext.setValue({...prospectsContext, assignmentId: assignmentPackage.id})}
              active={assignmentPackage.id === activeAssignment.id}
              assignmentPackage={assignmentPackage}
            />
          ))}
        </div>

        <Button
          disabled={rightButton.disabled}
          onClick={rightButton.onClick}
          shape={"circular"}
          wrapperClassName={"self-center"}
        >
          <ArrowRightIcon className={"w-6"} />
        </Button>
      </TabsContainer>

      {hasNoProspects && <NoProspects />}

      {activeAssignment.prospects_draft_count > 0 && (
        <Section
          title={t("Prospects_Draft_Title", {count: activeAssignment.prospects_draft_count})}
          salesCycleId={salesCycle.id}
          assignmentId={activeAssignment.id}
          isAddButtonVisible
          isSendButtonVisible={canSendDraft ? true : "disabled"}
          approveProspectsButtonType={"skip notification"}
          listQuery={useDraftTableQuery}
          paginationKey={"prospects admin draft"}
          isWarningsOnly={isWarningsOnly}
        />
      )}
      {activeAssignment.prospects_ready_to_approve_count > 0 && (
        <Section
          title={
            <>
              {t("Prospects_WaitingForApproval_Title", {
                count: activeAssignment.prospects_ready_to_approve_count,
              })}
              {activeAssignment.prospects_sent_for_approval_at && (
                <span className={"ml-2 text-sm font-normal"}>
                  {t("Prospects_WaitingForApproval_Subtitle", {
                    date: apiDateToJS(activeAssignment.prospects_sent_for_approval_at).toLocaleString(
                      getCurrentLanguage(),
                      {month: "long", day: "numeric"}
                    ),
                    time: apiDateToJS(activeAssignment.prospects_sent_for_approval_at).toLocaleString(
                      getCurrentLanguage(),
                      {timeStyle: "short"}
                    ),
                  })}
                </span>
              )}
            </>
          }
          salesCycleId={salesCycle.id}
          assignmentId={activeAssignment.id}
          isAddButtonVisible={activeAssignment.prospects_draft_count === 0}
          isRejectActionVisible
          approveProspectsButtonType={"auto approve"}
          listQuery={useWaitingForApprovalTableQuery}
          paginationKey={"prospects admin waiting for approval"}
        />
      )}
      {activeAssignment.prospects_finished_count > 0 && (
        <Section
          title={
            <>
              {t("Prospects_Finished_Title", {count: activeAssignment.prospects_finished_count})}
              <span className={"ml-2 text-sm font-normal"}>
                {t("Prospects_Finished_Subtitle", {
                  approved: activeAssignment.prospects_approved_count,
                  rejected: activeAssignment.prospects_finished_count - activeAssignment.prospects_approved_count,
                })}
              </span>
            </>
          }
          salesCycleId={salesCycle.id}
          assignmentId={activeAssignment.id}
          isAddButtonVisible={
            activeAssignment.prospects_draft_count === 0 && activeAssignment.prospects_ready_to_approve_count === 0
          }
          isRejectedOnlyToggleVisible
          isCRMButtonVisible={data => data?.data.meta?.sync_crm_button ?? false}
          listQuery={useFinishedTableQuery}
          paginationKey={"prospects admin finished"}
        />
      )}
    </LayoutBlock>
  )
}
