import React from "react"
import {useTranslation} from "react-i18next"
import {MagnifyingGlassIcon} from "@heroicons/react/16/solid"

import {LeadsFilteringContext} from "../../routes/Leads/context.ts"
import {InputBase, TInputBaseProps} from "../fields/Input.tsx"
import {InputFilter} from "./InputFilter.tsx"

export const SearchBoxFilter: React.FC<TInputBaseProps> = ({...inputProps}) => {
  const {t} = useTranslation()

  const basicFilteringSearchString = LeadsFilteringContext.useOptionalContext()?.searchString

  return basicFilteringSearchString ? (
    <InputFilter
      {...inputProps}
      property={basicFilteringSearchString}
      placeholder={t("SearchPlaceholder")}
      Icon={MagnifyingGlassIcon}
    />
  ) : (
    <InputBase placeholder={t("SearchPlaceholder")} Icon={MagnifyingGlassIcon} {...inputProps} disabled />
  )
}
