import React from "react"
import {Trans, useTranslation} from "react-i18next"

import Button from "../../../../../components/Button.tsx"
import {commonTransComponents} from "../../../../../utils/i18n.tsx"
import {ProspectsFromLeadsFrame} from "../../ProspectsFromLeadsFrame.tsx"
import {ProspectsFromLeadsLayout} from "../../ProspectsFromLeadsLayout.tsx"
import {AdvancedLeadsContext, PFLContext} from "../context/context.ts"

export const AdvancedSearchErrorStage: React.FC = () => {
  const {t} = useTranslation()

  const {setStage} = AdvancedLeadsContext.useContext()
  const {setIsFiltersOpen} = PFLContext.useContext()

  const handleReset = React.useCallback(() => {
    setStage("filtering")
    setIsFiltersOpen(true)
  }, [setIsFiltersOpen, setStage])

  return (
    <ProspectsFromLeadsLayout>
      <ProspectsFromLeadsFrame>
        <div className={"flex flex-col items-center gap-10"}>
          <div className={"flex flex-col items-center gap-4"}>
            <div className={"flex flex-col items-center gap-10"}>
              <h1 className={"text-8xl"}>:(</h1>
              <h2 className={"text-4xl font-semibold"}>{t("Prospects_ImportModal_LeadsStep_Advanced_Error_Title")}</h2>
            </div>
            <div className={"text-center text-cr-grey-50"}>
              <Trans
                i18nKey={"Prospects_ImportModal_LeadsStep_Advanced_Error_Text"}
                components={commonTransComponents}
              />
            </div>
          </div>
          <Button onClick={handleReset}>{t("Prospects_ImportModal_LeadsStep_Advanced_Error_Button")}</Button>
        </div>
      </ProspectsFromLeadsFrame>
    </ProspectsFromLeadsLayout>
  )
}
