import React from "react"

import {AdvancedLeadsContext} from "./context/context.ts"
import {useAdvancedLeads} from "./context/useAdvancedLeads.ts"
import {AdvancedSearchAddingStage} from "./stages/AdvancedSearchAddingStage.tsx"
import {AdvancedSearchErrorStage} from "./stages/AdvancedSearchErrorStage.tsx"
import {AdvancedSearchFilteringStage} from "./stages/AdvancedSearchFilteringStage.tsx"
import {AdvancedSearchSearchingStage} from "./stages/AdvancedSearchSearchingStage.tsx"
import {AdvancedSearchResetPrompt} from "./AdvancedSearchResetPrompt.tsx"
import {AdvancedSearchTurnOffPrompt} from "./AdvancedSearchTurnOffPrompt.tsx"

export const AdvancedSearch: React.FC = () => {
  const advancedLeadsContextValue = AdvancedLeadsContext.useProviderValue(useAdvancedLeads())
  const {stage} = advancedLeadsContextValue

  return (
    <AdvancedLeadsContext value={advancedLeadsContextValue}>
      {stage === "error" && <AdvancedSearchErrorStage />}
      {stage === "filtering" && <AdvancedSearchFilteringStage />}
      {stage === "searching" && <AdvancedSearchSearchingStage />}
      {stage === "adding" && <AdvancedSearchAddingStage />}

      <AdvancedSearchResetPrompt />
      <AdvancedSearchTurnOffPrompt />
    </AdvancedLeadsContext>
  )
}
