import React from "react"

import {TOrderBy} from "../../../../../components/Table/utils/shared.ts"
import {ADetailedAdvancedSearchDraftedProspect} from "../../../../../services/types.generated.ts"
import {useRemovedSearchIds, useRows} from "../hooks.ts"
import {TAddingStageColumn, TAddingStageContext, TAddingStageRow, TTab} from "../types.ts"

export const useAddingStage = (
  prospects: ADetailedAdvancedSearchDraftedProspect[],
  orderBy: TOrderBy<TAddingStageColumn>,
  setOrderBy: React.Dispatch<React.SetStateAction<TOrderBy<TAddingStageColumn>>>
): TAddingStageContext => {
  const [tab, setTab] = React.useState<TTab>("added")
  const [mainContacts, setMainContacts] = React.useState<Record<number, number>>({})

  const {removedSearchIds, addRemovedSearchId, removeRemovedSearchId} = useRemovedSearchIds(prospects)

  const rows = useRows(prospects, tab, removedSearchIds)

  React.useEffect(() => {
    if (tab !== "added") {
      return
    }

    if (rows.length === 0) {
      setMainContacts({})
      return
    }

    const prospectsByGroupId = Object.groupBy(
      rows.filter(prospect => prospect.group_id != null),
      prospect => prospect.group_id!
    )

    setMainContacts(previousMainContacts =>
      Object.entries(prospectsByGroupId).reduce(
        (acc, entry) => {
          const groupId = Number(entry[0])
          const prospectIds = entry[1]?.map(prospect => prospect.id)
          const previousMainContact = previousMainContacts[groupId]

          if (prospectIds == null) {
            return acc
          }

          if (previousMainContact == null) {
            return {...acc, [groupId]: prospectIds[0]}
          }

          return {...acc, [groupId]: prospectIds.includes(previousMainContact) ? previousMainContact : prospectIds[0]}
        },
        {} as Record<number, number>
      )
    )
  }, [rows, tab])

  const handleSelectMainContact = React.useCallback(
    (prospect: TAddingStageRow) => {
      if (prospect.group_id == null || removedSearchIds.includes(prospect.id)) {
        return
      }
      setMainContacts(previousMainContacts => ({...previousMainContacts, [prospect.group_id!]: prospect.id}))
    },
    [removedSearchIds]
  )

  return React.useMemo(
    () => ({
      mainContacts,
      onSelectMainContact: handleSelectMainContact,
      tab,
      setTab,
      removedSearchIds,
      addRemovedSearchId,
      removeRemovedSearchId,
      rows,
      orderBy,
      setOrderBy,
    }),
    [
      addRemovedSearchId,
      handleSelectMainContact,
      mainContacts,
      orderBy,
      removeRemovedSearchId,
      removedSearchIds,
      rows,
      setOrderBy,
      tab,
    ]
  )
}
