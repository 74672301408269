import React from "react"
import {useTranslation} from "react-i18next"
import {ArrowPathIcon} from "@heroicons/react/24/outline"

import {CheckboxBase} from "../../../../components/fields/Checkbox.tsx"
import {Link} from "../../../../components/Link.tsx"
import {useUserSettingsOrLogout} from "../../../../queries/user.ts"
import {EGTMEvent, emitGTMEvent} from "../../../../utils/gtm.tsx"
import {isSaaSSalespersonUser, isSalesPersonUser} from "../../../../utils/types.ts"
import {AdvancedLeadsContext, PFLContext} from "./context/context.ts"

export const AdvancedSearchToggle: React.FC = () => {
  const {t} = useTranslation()

  const {user} = useUserSettingsOrLogout()
  const advancedSearch = AdvancedLeadsContext.useOptionalContext()
  const {setIsFiltersOpen, setIsAdvancedFiltering, isAdvancedFiltering} = PFLContext.useContext()

  const handleToggle = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    event => {
      if (event.target.checked) {
        emitGTMEvent({event: EGTMEvent.LEADS_ADVANCED_SEARCH_TRIGGERED})
        setIsAdvancedFiltering(true)
        setIsFiltersOpen(true)
      } else {
        advancedSearch?.setPrompt.call(null, "turn off")
      }
    },
    [advancedSearch?.setPrompt, setIsAdvancedFiltering, setIsFiltersOpen]
  )

  const handleResetFilters = React.useCallback(() => {
    advancedSearch?.setPrompt.call(null, "reset")
  }, [advancedSearch?.setPrompt])

  if (!isSalesPersonUser(user) && !isSaaSSalespersonUser(user)) {
    return null
  }

  return (
    <>
      <CheckboxBase onChange={handleToggle} checked={isAdvancedFiltering} toggle>
        <span className={"text-base text-cr-black"}>{t("Prospects_ImportModal_LeadsStep_Advanced_Toggle")}</span>
      </CheckboxBase>
      {advancedSearch && (
        <Link
          onClick={handleResetFilters}
          className={"flex items-center gap-2"}
          disabled={advancedSearch.stage === "filtering"}
          variant={"black"}
          flipUnderline
        >
          <ArrowPathIcon className={"size-5 shrink-0"} />
          {t("Prospects_ImportModal_LeadsStep_Advanced_Reset")}
        </Link>
      )}
    </>
  )
}
