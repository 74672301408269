import React from "react"
import {useTranslation} from "react-i18next"
import {InformationCircleIcon} from "@heroicons/react/24/outline"

import {TColumnMeta} from "../../../../../components/Table/utils/shared.ts"
import {Tooltip} from "../../../../../components/Tooltip.tsx"
import {enumTranslKey, TTranslatedEnums} from "../../../../../utils/i18n.tsx"
import {TAllColumns, TCommonRow} from "../types.ts"
import {tableLoadingIndicator} from "./common.tsx"

export const useCompanySizeColumn = <TColumns extends TAllColumns, TRow extends TCommonRow>(): TColumnMeta<
  TColumns,
  TRow
> => {
  const {t} = useTranslation()

  return React.useMemo(
    () => ({
      column: "size" as TColumns,
      headerCellClassName: "!bg-cr-blue-super-light",
      HeaderCellValue: () => (
        <div className={"flex items-center gap-2"}>
          <div>{t("Leads_Table_CompanySize_Text")}</div>
          <Tooltip buttonNode={<InformationCircleIcon className={"size-5"} />}>
            <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>
              {t("Leads_Table_CompanySize_Tooltip")}
            </div>
          </Tooltip>
        </div>
      ),
      CellValue: ({row}) =>
        row.id === "loading"
          ? tableLoadingIndicator
          : row.company_size
            ? t(enumTranslKey("CompanySizeFilter", numEmployeesToCompanySize(Number(row.company_size)) ?? ""), "-")
            : "-",
    }),
    [t]
  )
}

const numEmployeesToCompanySize = (numEmployees: number): keyof TTranslatedEnums["CompanySizeFilter"] | undefined => {
  if (numEmployees > 1000) {
    return "1000,1000000"
  }
  if (numEmployees > 500) {
    return "501,1000"
  }
  if (numEmployees > 200) {
    return "201,500"
  }
  if (numEmployees > 100) {
    return "101,200"
  }
  if (numEmployees > 50) {
    return "51,100"
  }
  if (numEmployees > 20) {
    return "21,50"
  }
  if (numEmployees > 10) {
    return "11,20"
  }
  if (numEmployees > 0) {
    return "1,10"
  }

  return undefined
}
