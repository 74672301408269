import React from "react"
import {useTranslation} from "react-i18next"
import {EyeIcon as EyeIconOutline} from "@heroicons/react/24/outline"
import {EyeIcon as EyeIconSolid} from "@heroicons/react/24/solid"

import {Link} from "../../../../../components/Link.tsx"
import {TColumnMeta} from "../../../../../components/Table/utils/shared.ts"
import {Tooltip} from "../../../../../components/Tooltip.tsx"
import {useAdvancedSearchMarkWebsiteViewedMutation} from "../../../../../queries/advancedSearch.ts"
import {addHttpToURL} from "../../../../../utils"
import {AdvancedLeadsContext} from "../context/context.ts"
import {TAllColumns, TCommonRow} from "../types.ts"
import {tableLoadingIndicator} from "./common.tsx"

export const useWebsiteColumn = <TColumns extends TAllColumns, TRow extends TCommonRow>(): TColumnMeta<
  TColumns,
  TRow
> => {
  const {t} = useTranslation()

  const {sessionId} = AdvancedLeadsContext.useContext()

  const markWebsiteViewedMutation = useAdvancedSearchMarkWebsiteViewedMutation()

  const handleLinkClick = React.useCallback(
    (row: TCommonRow) => (e: React.PointerEvent) => {
      if (row.id === "loading") {
        return
      }

      // We need to handle the click with onPointerUp instead of onClick to make sure we grab middle mouse clicks

      if (e.button === 2) {
        // Right mouse button
        return
      }

      if (sessionId == null) {
        return
      }
      markWebsiteViewedMutation.mutateAsync.call(null, {sessionId, prospectId: row.id})
    },
    [markWebsiteViewedMutation.mutateAsync, sessionId]
  )

  return React.useMemo(
    () => ({
      column: "website" as TColumns,
      headerCellClassName: "!bg-cr-blue-super-light",
      HeaderCellValue: () => t("Leads_Table_Website"),
      CellValue: ({row}) =>
        row.id === "loading" ? (
          tableLoadingIndicator
        ) : row.website ? (
          <div className={"flex items-center gap-4"}>
            <Link to={addHttpToURL(row.website)} target={"_blank"} flipUnderline onPointerUp={handleLinkClick(row)}>
              {row.website}
            </Link>
            {row.viewed ? (
              <Tooltip buttonNode={<EyeIconSolid className={"size-5 text-cr-blue"} />}>
                <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>
                  {t("Prospects_ImportModal_LeadsStep_Advanced_WebsiteTooltip_Viewed")}
                </div>
              </Tooltip>
            ) : (
              <Tooltip
                buttonNode={
                  <EyeIconOutline
                    className={"size-5 text-cr-grey-50 opacity-0 transition-all group-hover/row:opacity-100"}
                  />
                }
              >
                <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>
                  {t("Prospects_ImportModal_LeadsStep_Advanced_WebsiteTooltip_NotViewed")}
                </div>
              </Tooltip>
            )}
          </div>
        ) : (
          "-"
        ),
    }),
    [handleLinkClick, t]
  )
}
