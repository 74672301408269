import {createSimpleContext} from "../../../../../utils/context.tsx"
import {TAddingStageContext, TAdvancedLeadsContext, TPFLContext, TSearchingStageContext} from "../types.ts"

export const AdvancedLeadsContext = createSimpleContext<TAdvancedLeadsContext>("advanced search")

export const SearchingStageContext = createSimpleContext<TSearchingStageContext>("searching stage")
export const AddingStageContext = createSimpleContext<TAddingStageContext>("adding stage")

/**
 * Prospects from leads context
 */
export const PFLContext = createSimpleContext<TPFLContext>("prospects from leads")
