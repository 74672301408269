import React from "react"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import {Link} from "./Link"

const SCROLL_HEIGHT_THRESHOLD = 2

export const ExpandableBox: React.FC<{
  lineClamp?: number
  children: React.ReactNode
}> = ({lineClamp = 4, children}) => {
  const {t} = useTranslation()
  const [isTextOverflowing, setIsTextOverflowing] = React.useState(false)
  const [isExpanded, setIsExpanded] = React.useState(false)

  const textContainerRef = React.useCallback((textContainer: HTMLElement | null) => {
    const isOverflowing =
      !!textContainer && textContainer.scrollHeight - SCROLL_HEIGHT_THRESHOLD > textContainer.clientHeight
    setIsTextOverflowing(isOverflowing)
    if (!isOverflowing) {
      setIsExpanded(false)
    }
  }, [])

  const handleShowMoreClick = () => setIsExpanded(true)

  const handleShowLessClick = () => setIsExpanded(false)

  return (
    <div className={"flex flex-col items-start gap-1"}>
      <div
        ref={textContainerRef}
        data-testid={"expandable-text-container"}
        style={
          {
            "--line-clamp": lineClamp,
          } as React.CSSProperties
        }
        className={twMerge(!isExpanded && "line-clamp-(--line-clamp) text-ellipsis")}
      >
        {children}
      </div>
      {isTextOverflowing && !isExpanded && (
        <Link data-testid={"show-more-link"} onClick={handleShowMoreClick}>
          {t("ExpandableBox_ShowMore")}
        </Link>
      )}
      {isExpanded && (
        <Link data-testid={"show-less-link"} onClick={handleShowLessClick}>
          {t("ExpandableBox_ShowLess")}
        </Link>
      )}
    </div>
  )
}
