import React from "react"

import {TOrderBy} from "../../../../../components/Table/utils/shared.ts"
import {AAdvancedSearchDraftedProspect} from "../../../../../services/types.generated.ts"
import {useRemovedSearchIds, useRows} from "../hooks.ts"
import {TSearchingStageColumn, TSearchingStageContext, TSearchingStageRow, TTab} from "../types.ts"

export const useSearchingStage = (
  prospects: AAdvancedSearchDraftedProspect[],
  orderBy: TOrderBy<TSearchingStageColumn>,
  setOrderBy: React.Dispatch<React.SetStateAction<TOrderBy<TSearchingStageColumn>>>,
  isLoadingData: boolean
): TSearchingStageContext => {
  const [tab, setTab] = React.useState<TTab>("added")

  const {removedSearchIds, addRemovedSearchId, removeRemovedSearchId} = useRemovedSearchIds(prospects)

  const rows = useRows(prospects, tab, removedSearchIds)
  const rowsWithLoading = React.useMemo<TSearchingStageRow[]>(() => {
    if (!isLoadingData || tab === "removed") {
      return rows
    }

    return [...rows, {id: "loading"}]
  }, [rows, isLoadingData, tab])

  return React.useMemo(
    () => ({
      tab,
      setTab,
      removedSearchIds,
      addRemovedSearchId,
      removeRemovedSearchId,
      rows: rowsWithLoading,
      orderBy,
      setOrderBy,
    }),
    [addRemovedSearchId, orderBy, removeRemovedSearchId, removedSearchIds, rowsWithLoading, setOrderBy, tab]
  )
}
