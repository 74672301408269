import React from "react"
import {useTranslation} from "react-i18next"
import {FolderPlusIcon} from "@heroicons/react/24/outline"

import {GenericErrorAlert} from "../../components/Alert"
import {Badge} from "../../components/Badge"
import {LayoutBlock} from "../../components/Layout/LayoutBlock"
import {Loading} from "../../components/Loading"
import Pagination from "../../components/Pagination"
import {RowCountPickerLS, usePageSize} from "../../components/RowCountPicker"
import {TabItem, TabsContainer} from "../../components/Tabs"
import {getCurrentLanguage} from "../../i18n"
import {useLeadsIndexQuery, useLeadsMetadataQuery, useRemovalRequestedLeadsIndexQuery} from "../../queries/leads"
import {CombineProviders} from "../../utils/context.tsx"
import {useDocumentTitle} from "../../utils/hooks"
import {AddLeadsButton} from "./components/AddLeadsButton"
import {DeleteModal} from "./components/DeleteModal"
import {EditingFlyout} from "./components/EditingFlyout"
import {ImportsHistoryButton} from "./components/ImportsHistoryButton"
import {DeletingContext, EditingContext, LeadsFilteringContext} from "./context"
import {DataTable} from "./DataTable"
import {useLeadsFiltering} from "./useLeadsFiltering"

enum ETab {
  ALL,
  REMOVAL_REQUESTED,
}

export const Leads: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("Leads_DocumentTitle"))

  const [tab, setTab] = React.useState(ETab.ALL)

  const leadsMetadataQuery = useLeadsMetadataQuery()
  const metadata = leadsMetadataQuery.data?.data.leads_metadata

  React.useEffect(() => {
    if (metadata?.removal_requested === 0 && tab === ETab.REMOVAL_REQUESTED) {
      setTab(ETab.ALL)
    }
  }, [tab, metadata?.removal_requested])

  if (leadsMetadataQuery.error) {
    return <GenericErrorAlert retry={leadsMetadataQuery.refetch} />
  }

  if (leadsMetadataQuery.isLoading || !metadata) {
    return <Loading size={"xl"} />
  }

  if (metadata.total_leads_in_db === 0) {
    return (
      <LayoutBlock outerClassName={"py-16 grow"} innerClassName={"flex flex-col gap-9"}>
        <div className={"flex items-center gap-4"}>
          <h1 className={"text-3xl font-semibold text-cr-black"}>{t("Leads_MainTitle")}</h1>
        </div>
        <div className={"flex grow items-center justify-center"}>
          <div className={"my-14 flex flex-col items-center gap-8"}>
            <FolderPlusIcon className={"w-12 text-cr-grey-30"} />
            <div className={"flex flex-col items-center gap-4"}>
              <h1 className={"text-2xl font-semibold"}>{t("Leads_NoLeads_Title")}</h1>
              <h2 className={"text-cr-grey-50"}>{t("Leads_NoLeads_Subtitle")}</h2>
              <AddLeadsButton />
            </div>
          </div>
        </div>
      </LayoutBlock>
    )
  }

  return (
    <LayoutBlock outerClassName={"py-16"} innerClassName={"flex flex-col gap-9"}>
      <div className={"flex items-center gap-4"}>
        <h1 className={"text-3xl font-semibold text-cr-black"}>{t("Leads_MainTitle")}</h1>
        <Badge color={"blue"} className={"font-bold"}>
          {Intl.NumberFormat(getCurrentLanguage()).format(metadata.total_leads_in_db ?? 0)}
        </Badge>
        <ImportsHistoryButton isImporting={metadata.import_in_progress} />
      </div>

      {metadata.removal_requested > 0 && (
        <TabsContainer>
          <TabItem isActive={tab === ETab.ALL} onClick={() => setTab(ETab.ALL)}>
            {t("Leads_Tabs_AllLeads")}
          </TabItem>
          <TabItem
            isActive={tab === ETab.REMOVAL_REQUESTED}
            onClick={() => setTab(ETab.REMOVAL_REQUESTED)}
            aria-label={t("Leads_Tabs_RemovalRequested")}
          >
            <div className={"flex items-baseline gap-2"}>
              <span>{t("Leads_Tabs_RemovalRequested")}</span>
              <Badge color={tab === ETab.REMOVAL_REQUESTED ? "light-blue" : "grey"} className={"px-2.5 py-0.5 text-xs"}>
                {metadata.removal_requested}
              </Badge>
            </div>
          </TabItem>
        </TabsContainer>
      )}

      {tab === ETab.ALL && (
        <LeadsSection
          addingIsAllowed
          total={metadata.total_leads_in_db}
          queryFn={useLeadsIndexQuery}
          paginationKey={"all leads"}
        />
      )}
      {tab === ETab.REMOVAL_REQUESTED && (
        <LeadsSection
          addingIsAllowed={false}
          removeReasonGDPROnly
          total={metadata.removal_requested}
          queryFn={useRemovalRequestedLeadsIndexQuery}
          paginationKey={"removal requested leads"}
        />
      )}
    </LayoutBlock>
  )
}

export const LeadsSection: React.FC<{
  addingIsAllowed: boolean
  total: number
  queryFn: typeof useLeadsIndexQuery
  removeReasonGDPROnly?: boolean
  paginationKey: string
}> = ({addingIsAllowed, queryFn, total, paginationKey, removeReasonGDPROnly}) => {
  const {t} = useTranslation()

  const leadsFiltering = LeadsFilteringContext.useProviderValue(useLeadsFiltering())

  const editContextValue = EditingContext.useProviderValue(null)
  const deleteContextValue = DeletingContext.useProviderValue(null)

  const indexQuery = queryFn({
    pageSize: usePageSize(paginationKey),
    ...leadsFiltering,
  })
  const indexData = indexQuery.data?.data

  if (indexQuery.error) {
    return <GenericErrorAlert retry={indexQuery.refetch} />
  }

  if (indexQuery.isLoading || !indexData?.meta?.pagination) {
    return <Loading size={"xl"} />
  }

  return (
    <CombineProviders
      providers={[
        LeadsFilteringContext.combined(leadsFiltering),
        EditingContext.combined(editContextValue),
        DeletingContext.combined(deleteContextValue),
      ]}
    >
      <div className={"flex items-center justify-between gap-8"}>
        <div data-testid={"leads current count"}>
          <span className={"font-bold"}>{indexData.meta.pagination.total_count ?? 0}</span> {t("Leads_Of")} {total}
        </div>
        {addingIsAllowed && <AddLeadsButton variant={"light"} />}
      </div>
      <DataTable data={indexData.leads} isLoading={indexQuery.isFetching} refetch={indexQuery.refetch} />
      <RowCountPickerLS total={indexQuery.pagination.total} autoHide paginationKey={paginationKey} />
      <Pagination {...indexQuery.pagination} autoHide />
      <EditingFlyout />
      <DeleteModal onDelete={leadsFiltering.clearRows} removeReasonGDPROnly={removeReasonGDPROnly} />
    </CombineProviders>
  )
}
