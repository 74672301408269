import React from "react"

import {TRemovedSearchIdsHandler, TTab} from "./types.ts"

export const useRemovedSearchIds = (prospects: Array<{id: number}>): TRemovedSearchIdsHandler => {
  const [removedSearchIds, setRemovedSearchIds] = React.useState<number[]>([])

  const removeRemovedSearchId = React.useCallback((id: number) => {
    setRemovedSearchIds(previousRemovedSearchIds => previousRemovedSearchIds.filter(removedId => removedId !== id))
  }, [])
  const addRemovedSearchId = React.useCallback((id: number) => {
    setRemovedSearchIds(previousRemovedSearchIds =>
      previousRemovedSearchIds.includes(id) ? previousRemovedSearchIds : [...previousRemovedSearchIds, id]
    )
  }, [])

  React.useEffect(() => {
    setRemovedSearchIds(previousRemovedSearchIds => {
      if (previousRemovedSearchIds.every(removedId => prospects.some(prospect => prospect.id === removedId))) {
        return previousRemovedSearchIds
      }

      return previousRemovedSearchIds.filter(removedId => prospects.some(prospect => prospect.id === removedId))
    })
  }, [prospects])

  return {
    removedSearchIds,
    addRemovedSearchId,
    removeRemovedSearchId,
  }
}

export function useRows<T extends {id: number}>(prospects: T[], tab: TTab, removedSearchIds: number[]): T[] {
  return React.useMemo(() => {
    return prospects.filter(row => removedSearchIds.includes(row.id) === (tab === "removed"))
  }, [prospects, tab, removedSearchIds])
}
