import React from "react"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import Button from "../../../components/Button.tsx"
import {SearchBoxFilter} from "../../../components/filters/SearchBoxFilter.tsx"
import {ProfileImage} from "../../../components/ProfileImage.tsx"
import {getCurrentLanguage} from "../../../i18n.ts"
import {useUserSettingsOrLogout} from "../../../queries/user.ts"
import {getFullName} from "../../../utils"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {isSaaSSalespersonUser} from "../../../utils/types.ts"
import {ProspectsContext} from "../shared/context.ts"
import {AdvancedSearchToggle} from "./AdvancedSearch/AdvancedSearchToggle.tsx"
import {PFLContext} from "./AdvancedSearch/context/context.ts"
import {FilterSidebar} from "./Filters/utilityComponents/FilterSidebar.tsx"

export const ProspectsFromLeadsLayout: React.FC<{
  nextStepButton?: React.ReactNode
  children: React.ReactNode
}> = ({nextStepButton, children}) => {
  const {t} = useTranslation()

  const {onClose, isFiltersOpen} = PFLContext.useContext()
  const {user} = useUserSettingsOrLogout()
  const {salesCycle, assignment, iteration} = ProspectsContext.useContext()

  if (assignment == null || iteration == null || salesCycle == null) {
    throw new Error("Error: assignment or iteration not set")
  }

  const assignmentSalesPerson = "sales_person" in assignment ? assignment.sales_person : null

  return (
    <div className={"flex min-h-full grow flex-col gap-10"}>
      <div className={"flex flex-col gap-4"}>
        <div className={"flex items-center justify-between gap-8"}>
          <h1 className={"text-3xl font-semibold text-cr-blue"}>{t("Prospects_ImportModal_LeadsStep_Title")}</h1>
          <div className={"flex items-center gap-4"}>
            <Button onClick={onClose} variant={"outlined"}>
              {t("Prospects_ImportModal_LeadsStep_Done")}
            </Button>
          </div>
        </div>

        <div className={"flex items-center gap-4"}>
          <ProfileImage
            src={assignmentSalesPerson?.profile_picture_thumbnail_url ?? undefined}
            alt={getFullName(assignmentSalesPerson)}
            className={"size-10 shrink-0"}
          />
          <AssignmentBreadcrumbs />
        </div>
      </div>

      <div className={"flex grow flex-col gap-4"}>
        <div className={"flex items-center justify-between gap-8"}>
          <div className={"flex items-center gap-8"}>
            <SearchBoxFilter borderClassName={twMerge("shrink-0 transition-all", isFiltersOpen ? "w-96" : "w-60")} />
            {isSaaSSalespersonUser(user) ? null : <AdvancedSearchToggle />}
          </div>

          {nextStepButton}
        </div>

        <div className={"relative flex min-h-96 grow items-stretch gap-4 transition-all"}>
          <div className={"absolute top-0 bottom-0 left-0 shrink-0"}>
            <FilterSidebar />
          </div>
          <div className={twMerge("shrink-0 transition-all", isFiltersOpen ? "w-96" : "w-10")} />
          <div className={twMerge("flex min-w-0 grow flex-col gap-4 transition-all")}>{children}</div>
        </div>
      </div>
    </div>
  )
}
export const AssignmentBreadcrumbs: React.FC = () => {
  const {assignment, iteration, salesCycle} = ProspectsContext.useContext()

  if (assignment == null || iteration == null || salesCycle == null) {
    throw new Error("Error: assignment or iteration not set")
  }

  const assignmentSalesPerson = "sales_person" in assignment ? assignment.sales_person : null

  const packageName = "package_name" in assignment ? assignment.package_name : null

  return (
    <span>
      {[
        <span key={"name"} className={"font-bold"}>
          {getFullName(assignmentSalesPerson) || packageName || assignment.prospects_mandatory_count}
        </span>,
        "company" in salesCycle && salesCycle.company?.name,
        salesCycle.name,
        apiDateToJS(iteration.start_date).toLocaleString(getCurrentLanguage(), {
          month: "long",
          year: "numeric",
        }),
      ]
        .filter(Boolean)
        .reduce<React.ReactNode[]>((acc, value) => {
          if (acc.length === 0) {
            return [value]
          }
          return [...acc, " - ", value]
        }, [])
        .map((value, i) => (
          <React.Fragment key={i}>{value}</React.Fragment>
        ))}
    </span>
  )
}
