import React from "react"

import {EGTMEvent, emitGTMEvent} from "../../../../utils/gtm.tsx"
import {AdvancedSearch} from "../../ProspectsFromLeads/AdvancedSearch/AdvancedSearch.tsx"
import {PFLContext} from "../../ProspectsFromLeads/AdvancedSearch/context/context.ts"
import {usePFLContext} from "../../ProspectsFromLeads/AdvancedSearch/context/usePFLContext.ts"
import {BasicSearch} from "../../ProspectsFromLeads/BasicSearch.tsx"

export const LeadsStep: React.FC<{onClose: () => void}> = ({onClose}) => {
  React.useEffect(() => {
    emitGTMEvent({event: EGTMEvent.LEADS_SEARCH})
  }, [])

  const pflContextValue = usePFLContext(onClose)
  const {isAdvancedFiltering} = pflContextValue

  return <PFLContext value={pflContextValue}>{isAdvancedFiltering ? <AdvancedSearch /> : <BasicSearch />}</PFLContext>
}
