import React from "react"

import {TFilterOption} from "../../../../../components/AutocompleteFilter.tsx"
import {useArrayFilterProperty, useFilterProperties} from "../../../../../utils/filters.ts"
import {TAdvancedLeadsContext} from "../types.ts"

export const useAdvancedLeads = (): TAdvancedLeadsContext => {
  const [stage, setStage] = React.useState<TAdvancedLeadsContext["stage"]>("filtering")
  const [prompt, setPrompt] = React.useState<TAdvancedLeadsContext["prompt"]>(undefined)
  const [removedSearchIds, setRemovedSearchIds] = React.useState<number[]>([])
  const [sessionId, setSessionId] = React.useState<TAdvancedLeadsContext["sessionId"]>(undefined)

  const filterProperties = useFilterProperties({
    positions: useArrayFilterProperty<TFilterOption[]>(),
    segments: useArrayFilterProperty<TFilterOption[]>(),
    countries: useArrayFilterProperty<Array<TFilterOption<number>>>(),
    cities: useArrayFilterProperty<TFilterOption[]>(),
  })

  return React.useMemo(() => {
    return {
      stage,
      setStage,
      prompt,
      setPrompt,
      removedSearchIds,
      setRemovedSearchIds,
      sessionId,
      setSessionId,
      ...filterProperties,
    }
  }, [filterProperties, prompt, removedSearchIds, sessionId, stage])
}
