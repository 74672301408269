import React from "react"
import {useTranslation} from "react-i18next"

import {Accordion} from "../../../../components/Accordion.tsx"
import {RadioBase} from "../../../../components/fields/RadioButton.tsx"
import {LeadsFilteringContext} from "../../../Leads/context.ts"
import {FilterTitle} from "./utilityComponents/FilterTitle.tsx"

export const Phone: React.FC = () => {
  const {t} = useTranslation()

  const {phone} = LeadsFilteringContext.useContext()

  return (
    <Accordion
      title={<FilterTitle property={phone}>{t("Prospects_ImportModal_LeadsStep_Filter_Phone_Title")}</FilterTitle>}
      defaultOpen={false}
      listClassName={"flex gap-4"}
    >
      <RadioBase onChange={() => phone.setValue(true)} checked={phone.value}>
        {t("Prospects_ImportModal_LeadsStep_Filter_Phone_Yes")}
      </RadioBase>
      <RadioBase onChange={() => phone.setValue(false)} checked={!phone.value}>
        {t("Prospects_ImportModal_LeadsStep_Filter_Phone_No")}
      </RadioBase>
    </Accordion>
  )
}
