import React from "react"
import {twMerge} from "tailwind-merge"

export const ProspectsFromLeadsFrame: React.FC<{children: React.ReactNode; className?: string}> = ({
  children,
  className,
}) => {
  return (
    <div
      className={twMerge(
        "flex grow items-center justify-center rounded-lg border border-cr-blue-light p-10",
        className
      )}
    >
      {children}
    </div>
  )
}
